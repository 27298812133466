import React,{useEffect, useState} from 'react'
import {Box, Button, Grid, IconButton, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Admin from '../Dashboard';
import { RobotoTypo } from '../../../utils/Typographies';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import  axios  from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router";

const rows = [
    { id: 1, title: 'Title 1', description: 'Description 1' },
    { id: 2, title: 'Title 2', description: 'Description 2' },
    { id: 3, title: 'Title 3', description: 'Description 3' },
    // Add more rows as needed
  ];



export default function TeamList() {
    const navigate = useNavigate()
    const [teamList, setTeamList] = useState([])
    const location = useLocation();
    const teamType = location?.pathname.replace('/admin/','')

    const getTeamByType = () => {
      const obj = {singaporeTeam:'getSingaporeTeam',taiwanTeam:'getTaiwanTeam', hongKongTeam:'getHongKongTeam',chinaTeam:'getChinaTeam'}
      return obj[teamType]
    }

    const getTeam = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${getTeamByType()}`);
              console.log('response get ',response)
              if(response.status == 200) {
                if (Array.isArray(response?.data)) {
                    setTeamList(response?.data !='' ? response?.data : []);
                } else if (typeof response?.data === 'object' && response?.data !== null) {
                    setTeamList(response?.data !='' ? [response?.data] : []);
                }
              }
        } catch(error) {

        }
      }

      useEffect(() => {
        setTeamList([])
        getTeam()
      },[teamType])


      const handleDelete = async(id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/deletePersonById/${id}`,{
              headers: {
                Authorization: localStorage.getItem('admin_token')
              }
            });
            if(response.status == 200) {
                getTeam()
            }
          } catch (error) {
            if(error.response.status == 403) {
              localStorage.clear()
              navigate('/admin/login')
        }
          }
      }
      const handleEdit = (id) => {
        navigate(`/admin/${teamType}/`+id)
      }

  return (
    <Admin>
        <Box sx={{display:'flex',justifyContent:'flex-end', width:'100%',mb:1,}}>
            <Button component="span" onClick={() => navigate(`/admin/${teamType}/add`)} variant="contained" sx={{ alignSelf:'center', mt:1,width:'fit-content', backgroundColor:'#177D84','&:hover': {backgroundColor: '#177D84'},}}>
                Add
            </Button>
        </Box>
        
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sno</TableCell>
            <TableCell>Team</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teamList?.length !=0 && teamList?.map((team, index) => (
            <TableRow key={team.id}>
                <TableCell>{index+1}</TableCell>
              <TableCell>{team.en.person_name}</TableCell>
              <TableCell>
                <IconButton aria-label="delete" onClick={() => handleDelete(team._id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton aria-label="edit" onClick={() => handleEdit(team._id)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Admin>
  )
}
