import React,{useState, useEffect} from 'react'
import Header from '../../../Header';
import Footer from '../../../Footer';
import PrimarySchoolHeader from './PrimarySchoolHeader';
import PrimarySchoolBody from './PrimarySchoolBody';
import PrimarySchoolRecommendation from './PrimarySchoolRecommendation';
import Share from '../../../Share';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SideOverlap from '../../../../pages/sideOverlap';

export default function PrimarySchool() {
    const [schoolList, setSchoolList] = useState([])
    const [data, setData] = useState([])
    const language = useSelector((state) => state.language.language);

    const getPrimarySchool = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getPrimarySchools`);
              console.log('getPrimarySchools response ',response)
              if(response.status == 200) {
                setSchoolList(response?.data)
                console.log('response?.data one',typeof response?.data)
                getData(Array.isArray(response?.data) ? response?.data : [response.data])
              }
        } catch(error) {

        }
      }

      useEffect(() => {
        getPrimarySchool()
      },[])
      
      const getData = (data) => {
        console.log('lang data ',data)
          //  const languageData = data?.map((item) => {
            let languageData
            if(language == 'en') {
               languageData = data?.length !=0 && data.map((item) => {
                return {
                id: item._id,
                data: item.en 
                }
               })
            } else {
                languageData = data?.length !=0 && data.map((item) => {
                    return {
                    id: item._id,
                    data_en: item.en,
                    data: item.ch 
                    }
                   })
            }
          // })
          setData(languageData)
      }

      useEffect(() => {
        if(schoolList?.length !=0 ) {
            console.log('daad ',schoolList)
          getData(schoolList)
        }
      },[language])

    return (
        <>
            <Header />
            <PrimarySchoolHeader />
            <PrimarySchoolBody />
            <Share />
            {data?.length !=0 && <PrimarySchoolRecommendation data={data}/>}
            <Footer />
            <SideOverlap />
        </>
    );
}
