import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../utils/headerTheme';
import ToastAlert from '../../utils/Alert';
import { Box, Button, Dialog, MenuItem, Select, styled, TextField, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import { ErrTypo } from '../../utils/Typographies';
import PhoneInput from 'react-phone-input-2';
import { emailRegex } from '../../utils/common';

const MailDialogBox = ({open,setOpen}) => {
    const { t } = useTranslation();
  const classes = useStyles();
  
  
  const [enquiryDetailsData, setEnquiryDetailsData] = useState({
    name: "",
    phone: "",
    countryCode: "",
    email: "",
    enquiryTitle: "",
    enquiryComments: "",
    country: "",
    contact: "",
  });
  const handleClose = () => {
    setOpen(false);
    setEnquiryDetailsData({
      name: "",
      phone: "",
      countryCode: "",
      email: "",
      enquiryTitle: "",
      enquiryComments: "",
      country: "",
      contact: "",
    });
  };
  const [toastStatus, setToastStatus] = useState({
    color: "",
    status: false,
    message: "",
    icon: "",
  });
  const RequiredIndicator = styled(Box)({
    color: "#FF202E",
    display: "inline-block",
  });

  const FormHeader = styled(Typography)({
    color: "#003B5C",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif !important",
  });
  const TextFieldStyle = {
    width: "100%",
    padding: 0,
  };
  const [countryList, setCountryList] = useState();
  const [loading, setLoading] = useState(false);
  const [errtext, seterrtext] = useState({
    enquiryDetailsData: {},
  });
  const getCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-countries`
      );

      if (response.status === 200) {
        setCountryList(response.data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };
  useEffect(() => {
    getCountries();
  }, []);
  const handleEnquiryDetails = (e) => {
    const { name, value } = e.target;
    if (name === "country" && value) {
      setEnquiryDetailsData((prevData) => ({
        ...prevData,
        country: value,
      }));
      seterrtext((prev) => {
        return {
          ...prev,
          enquiryDetailsData: {
            ...prev.enquiryDetailsData,
            country: "",
          },
        };
      });
    }
    setEnquiryDetailsData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    seterrtext((prev) => {
      return {
        ...prev,
        enquiryDetailsData: {
          ...prev.enquiryDetailsData,
          [name]: "",
        },
      };
    });
  };
  const handleMobileInput = (value, country, e) => {
    setEnquiryDetailsData((prev) => {
      return {
        ...prev,

        ["contact"]: value,
        ["countryCode"]: `+${country.dialCode}`,
        ["phone"]: value.slice(country.dialCode.length),
      };
    });

    seterrtext((prev) => {
      return {
        enquiryDetailsData: {
          ...prev.enquiryDetailsData,
          ["contact"]: "",
          ["phone"]: "",
          ["countryCode"]: "",
        },
      };
    });
  };
  const isValid = () => {
    let isError = false;
    for (const key in enquiryDetailsData) {
      if (key === "name" && enquiryDetailsData[key] === "") {
        seterrtext((prev) => {
          return {
            ...prev,
            enquiryDetailsData: {
              ...prev.enquiryDetailsData,
              [key]: "Please enter a name.",
            },
          };
        });
        isError = true;
      } else if (key === "email") {
        if (enquiryDetailsData[key] === "") {
          seterrtext((prev) => {
            return {
              ...prev,
              enquiryDetailsData: {
                ...prev.enquiryDetailsData,
                [key]: "Please enter your email",
              },
            };
          });
          isError = true;
        } else if (!emailRegex.test(enquiryDetailsData[key])) {
          seterrtext((prev) => {
            return {
              ...prev,
              enquiryDetailsData: {
                ...prev.enquiryDetailsData,
                [key]: "Error! Invalid Email Format",
              },
            };
          });
          isError = true;
        }
      } else if (key === "contact") {
        if (enquiryDetailsData[key] === "") {
          seterrtext((prev) => {
            return {
              ...prev,
              enquiryDetailsData: {
                ...prev.enquiryDetailsData,
                [key]: "Please enter your phone number",
              },
            };
          });
          isError = true;
        }
      } else if (key === "country") {
        if (enquiryDetailsData[key] === "") {
          seterrtext((prev) => {
            return {
              ...prev,
              enquiryDetailsData: {
                ...prev.enquiryDetailsData,
                [key]: "Please select your country",
              },
            };
          });
          isError = true;
        }
      } else if (key === "enquiryTitle") {
        if (enquiryDetailsData[key] === "") {
          seterrtext((prev) => {
            return {
              ...prev,
              enquiryDetailsData: {
                ...prev.enquiryDetailsData,
                [key]: "Please enter the Query Title",
              },
            };
          });
          isError = true;
        }
      } else if (key === "enquiryComments") {
        if (enquiryDetailsData[key] === "") {
          seterrtext((prev) => {
            return {
              ...prev,
              enquiryDetailsData: {
                ...prev.enquiryDetailsData,
                [key]: "Please enter the Query Comments",
              },
            };
          });
          isError = true;
        }
      }
    }

    return isError;
  };
  const AddEnquiryDetails = async () => {
    try {
      setLoading(true);
      const formData = {
        name: enquiryDetailsData.name,
        email: enquiryDetailsData.email,
        querySubject: enquiryDetailsData.enquiryComments,
        queryTitle: enquiryDetailsData.enquiryComments,
        country: enquiryDetailsData.country,
        phone: enquiryDetailsData.phone,
        country_code: enquiryDetailsData.countryCode,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/send-enquiry`,
        formData
      );

      if (response?.data?.status == "SUCCESS") {
        setToastStatus({
          color: "green",
          status: true,
          message: response.data.message,
          icon: "tick",
        });
        setLoading(false);
        setOpen(false);
        setEnquiryDetailsData({
          name: "",
          phone: "",
          countryCode: "",
          email: "",
          enquiryTitle: "",
          enquiryComments: "",
          country: "",
          contact: "",
        });
        setTimeout(() => {
          setToastStatus({ color: "", status: false });
        }, 3000);
      } else if (response?.data?.status == "FAILED") {
        setToastStatus({
          color: "RED",
          status: true,
          message: response.data.message,
          icon: "close",
        });
        setLoading(false);
        setTimeout(() => {
          setToastStatus({ color: "", status: false });
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    const isError = await isValid();
    console.log("err", errtext, enquiryDetailsData);
    if (isError) {
      return;
    } else {
      AddEnquiryDetails();
    }
  };
  return (
    <>
    <ToastAlert
      message={toastStatus?.message}
      status={toastStatus?.status}
      color={toastStatus?.color}
      icon={toastStatus?.icon}
      closeFunc={() => setToastStatus({ color: "", status: false })}
    />
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      sx={{ zIndex: 10000 }}
      onClose={handleClose}
    >
      <CloseIcon
        sx={{
          position: "absolute",
          right: "10px",
          top: "10px",
          color: "red",
          cursor: "pointer",
        }}
        onClick={() => {
          handleClose();
        }}
        fontSize="large"
      />

      <Box
        display="flex"
        flexDirection={"column"}
        gap={"20px"}
        sx={{ width: "100%", marginTop: "20px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "space-between",
            px: "30px",
            pt: "30px",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "45%" } }}>
            <FormHeader>
              Name{" "}
              <RequiredIndicator
                sx={{ color: "#FF202E", display: "inline-block" }}
              >
                *
              </RequiredIndicator>
            </FormHeader>
            <TextField
              sx={TextFieldStyle}
              name="name"
              inputProps={{ maxLength: 30 }}
              value={enquiryDetailsData.name}
              onChange={handleEnquiryDetails}
            />
            {errtext?.enquiryDetailsData?.name && (
              <ErrTypo>{errtext?.enquiryDetailsData?.name}</ErrTypo>
            )}
          </Box>

          <Box
            sx={{
              width: { xs: "100%", sm: "45%" },
              mt: { xs: "20px", sm: "0px" },
            }}
          >
            <FormHeader>
              Email{" "}
              <RequiredIndicator
                sx={{ color: "#FF202E", display: "inline-block" }}
              >
                *
              </RequiredIndicator>
            </FormHeader>

            <TextField
              sx={TextFieldStyle}
              name="email"
              type="email"
              inputProps={{ maxLength: 30 }}
              value={enquiryDetailsData.email}
              onChange={handleEnquiryDetails}
              variant="outlined"
            />
            {errtext?.enquiryDetailsData?.email && (
              <ErrTypo>{errtext?.enquiryDetailsData?.email}</ErrTypo>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              sm: "row",
            },

            px: "30px",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "45%" } }}>
            <FormHeader>
              Phone Number{" "}
              <RequiredIndicator
                sx={{ color: "#FF202E", display: "inline-block" }}
              >
                *
              </RequiredIndicator>
            </FormHeader>{" "}
            <Box sx={{ marginTop: "8px" }}>
              <PhoneInput
                country={"us"}
                buttonStyle={{
                  borderLeftRadius: "8px",
                }}
                value={enquiryDetailsData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(value, country, e)
                }
                inputStyle={{
                  width: "100%",
                  borderRadius: "8px",
                  height: "51px",
                  color: "#111927",
                }}
              />
            </Box>
            {errtext?.enquiryDetailsData?.contact && (
              <ErrTypo>{errtext?.enquiryDetailsData?.contact}</ErrTypo>
            )}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "45%" },
              mt: { xs: "20px", sm: "0px" },
            }}
          >
            <FormHeader>
              Country{" "}
              <RequiredIndicator
                sx={{ color: "#FF202E", display: "inline-block" }}
              >
                *
              </RequiredIndicator>
            </FormHeader>

            <Select
              name="country"
              value={enquiryDetailsData.country}
              onChange={(e) => handleEnquiryDetails(e)}
            >
              {countryList?.length > 0 &&
                countryList?.map((value, index) => (
                  <MenuItem
                    sx={{ color: "black" }}
                    key={index}
                    value={value}
                  >
                    {value}
                  </MenuItem>
                ))}
            </Select>
            {errtext?.enquiryDetailsData?.country && (
              <ErrTypo>{errtext?.enquiryDetailsData?.country}</ErrTypo>
            )}
          </Box>
        </Box>
        <Box sx={{ px: "30px" }}>
          <Box display="flex" justifyContent={"space-between"}>
            <FormHeader>
              Query Subject{" "}
              <RequiredIndicator
                sx={{ color: "#FF202E", display: "inline-block" }}
              >
                *
              </RequiredIndicator>
            </FormHeader>
            <Typography
              sx={{ color: "#6C737F", fontSize: "12px", fontWeight: 400 }}
            >
              {enquiryDetailsData.enquiryTitle.length}/50
            </Typography>
          </Box>

          <TextField
            sx={TextFieldStyle}
            onChange={handleEnquiryDetails}
            name={"enquiryTitle"}
            inputProps={{ maxLength: 50 }}
            value={enquiryDetailsData.enquiryTitle}
            variant="outlined"
          />
          {errtext?.enquiryDetailsData?.enquiryTitle && (
            <ErrTypo>{errtext?.enquiryDetailsData?.enquiryTitle}</ErrTypo>
          )}
        </Box>
        <Box sx={{ px: "30px" }}>
          <Box display="flex" justifyContent="space-between">
            <FormHeader>
              Message/Query{" "}
              <RequiredIndicator
                sx={{ color: "#FF202E", display: "inline-block" }}
              >
                *
              </RequiredIndicator>
            </FormHeader>
            <Typography
              sx={{ color: "#6C737F", fontSize: "12px", fontWeight: 400 }}
            >
              {enquiryDetailsData.enquiryComments.length}/200
            </Typography>
          </Box>
          <TextField
            multiline
            rows={3}
            inputProps={{ maxLength: 200 }}
            name={"enquiryComments"}
            sx={{
              "& .MuiInputBase-root": {
                padding: 0,
              },
            }}
            value={enquiryDetailsData.enquiryComments}
            onChange={handleEnquiryDetails}
            variant="outlined"
            fullWidth
          />
          {errtext?.enquiryDetailsData?.enquiryComments && (
            <ErrTypo>
              {errtext?.enquiryDetailsData?.enquiryComments}
            </ErrTypo>
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "150px",

              marginTop: "20px",
              marginBottom: "30px",
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Dialog>
  </>
  )
}

export default MailDialogBox