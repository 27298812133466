import React, { useState } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel, Button, InputAdornment, IconButton } from '@mui/material'
import { RobotoTypo } from '../../utils/Typographies'
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Hover1 } from '../../assets/ContactUs/DateIcon.svg';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import { ApiService } from '../../utils/common';


const useStyles = makeStyles((theme) => ({
    TextField: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", padding:'0' }
    },

    selectIcon: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
            color: '#177D84', // Set your desired color here
        },
    },
    adornment: {
        paddingRight: 0, // Adjust the padding around the InputAdornment
      },



}));


export default function AdminLogin() {
    const [qualification, setQualification] = React.useState('');
    const classes = useStyles();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({email:"", password:""})
    const [dataError, setDataError] = useState({email:"", password:""})
    const navigate = useNavigate()

    const togglePasswordVisibility = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleAgeChange = (event) => {
        setQualification(event.target.value);
    };

    const handleChange = (field, e) => {
        const {value} = e.target
        setData((prev) => ({...prev, [field]:value}))

        setDataError((prev) => ({...prev, [field]:""}))
    }

    const validation = () => {
        let errors = []
        if(data.email == "") {
            setDataError(prev=> ({...prev, email:"Email is required"}))
            errors.push(false)
        } else {
            setDataError(prev=> ({...prev, email:""}))
            errors.push(true)
        }
        if(data.password == "") {
            setDataError(prev=> ({...prev, password:"Password is required"}))
            errors.push(false)
        } else {
            setDataError(prev=> ({...prev, password:""}))
            errors.push(true)
        }
        let errorStatus = errors.includes(false)
        return !errorStatus
    }
console.log('data ',data)
    const handleSubmit = async() => {
        const validate = validation()
        console.log('validate ',validate)
        if(validate) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/adminlogin`, data)
                console.log('response ',response)
                if(response.status == 200) {
                    localStorage.removeItem('student_token')
                    localStorage.setItem('admin_token', response.data.access_token)
                    localStorage.setItem('role_name', response.data.role_name)
                    navigate('/admin/carousel')
                }
            } catch(err) {
                console.log('err ',err)
            }
        }
    }


    return (
        <Header>
              <Box sx={{ padding: '4% 10%', backgroundColor: '#F5DFBA', display:'flex', justifyContent:'center' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                width:{xs:'100%',md:'50%'},
                backgroundColor:'#F7F7F7',
                padding:'30px',
                borderRadius:'12px'
            }}>
                <Box sx={{ display: 'flex', gap: '90px', flexDirection: { xs: 'column', sm: 'row' },  }}>
                    <Box sx={{display:'flex', flexDirection:'column',  flex:1, gap:'30px'}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '25px', textAlign: 'center' }}>
                            Login 
                        </RobotoTypo>
                    
                        <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                Email
                            </RobotoTypo>
                            <TextField onChange={(e) => handleChange('email',e)} className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                            <RobotoTypo sx={{ color: '#FF202E', display: 'inline-block', fontWeight: '500', fontSize: '13px',  }}>{dataError.email} </RobotoTypo>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '90px', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Box sx={{ flex: '1' }}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            Password 
                        </RobotoTypo>
                        <Box sx={{position:'relative', flex: '1', width: '100%', marginTop: "10px", }}>
                        <TextField
                         onChange={(e) => handleChange('password',e)}
                         InputProps={{
                            border: 'none',
                            type: showPassword ? 'text' : 'password', // Toggle between text and password type
                              }}
                        className={classes.TextField} variant="outlined"  sx={{flex:1, width:'100%'}} />
                        <RobotoTypo sx={{ color: '#FF202E', display: 'inline-block', fontWeight: '500', fontSize: '13px',  }}>{dataError.password} </RobotoTypo>
                                <IconButton className={classes.iconButton}  sx={{position:'absolute', right:'20px',top:'25%' }} onClick={togglePasswordVisibility} edge="end">
                                  {showPassword ? <Visibility /> :  <VisibilityOff />}
                                </IconButton>
                        </Box>
                     
                    </Box>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <Button  variant='contained' onClick={handleSubmit} sx={{
                        borderRadius: '50px', textAlign: 'center', backgroundColor: '#177d84',
                        '&:hover': {
                            backgroundColor: '#177d84',
                        },
                    }} >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', padding: '6px 5px' }}>
                            <RobotoTypo sx={{ color: '#FFFFFF', letterSpacing: '0.02em', textTransform: 'none', lineHeight: '32px', fontWeight: '500', fontSize: { xs: 16, sm: 18 } }}>Login</RobotoTypo>
                        </Box>
                    </Button>
                </Box>

            </Box>
        </Box >
        <Footer />
        </Header>
      
    )
}
