import React, { useState, useEffect } from 'react'
import { Box, TextField, Select, FormControl, Stack, MenuItem, CircularProgress, InputLabel, Button, InputAdornment, IconButton, Checkbox } from '@mui/material'
import { RobotoTypo, ErrTypo } from '../utils/Typographies'
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Hover1 } from '../assets/ContactUs/DateIcon.svg';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import formChat from "../assets/png/form_chat.png"
import formfb from "../assets/png/form_fb.png"
import formline from "../assets/png/form_line.png"
import formwp from "../assets/png/form_wp.png"
import loginWeChat from '../assets/png/loginWeChat.png';
import loginFB from '../assets/png/loginFB.png';
import loginWP from '../assets/png/loginWP.png';
import loginLine from '../assets/png/loginLine.png';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { emailRegex } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ToastAlert from '../utils/Alert'; 
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    TextField: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", padding: '0' }
    },

    selectIcon: {
        "& .MuiInputBase-input": { padding: '10px 10px', borderRadius: '5px', backgroundColor: 'white', boxShadow: '2px 2px 4px 0px #00000040 inset' },
        "& fieldset": { border: "none", },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
            color: '#177D84', // Set your desired color here
        },
    },
    adornment: {
        paddingRight: 0, // Adjust the padding around the InputAdornment
    },



}));


export default function UserLogin({userDetails, setUserDetails}) {
    const [qualification, setQualification] = React.useState('');
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [formValues, setFormValues] = useState({ email: '', password: '' });
    const [formErrors, setFormErrors] = useState({ email: '', password: '' });
    const [termsAndCondition, setTermsAndCondition] = useState(false)
    const [toastStatus,setToastStatus] = useState({color:'',status:false,message:'',icon:''})
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const [previousSchoolType, setPreviousSchoolType] = useState('')
    const [previousRoute, setPreviousRoute] = useState('')

    useEffect(() => {
        if(!localStorage.getItem('schoolType') && !localStorage.getItem('schoolRoute')) {
            const currentPath = location.pathname;
            const previousPath = location.state?.from || '/default-path';
            setPreviousRoute(location.state?.routes)
            localStorage.setItem('schoolType',previousPath)
            localStorage.setItem('schoolRoute',location.state?.routes)
            console.log('location.state ',location.state)
            
            setPreviousSchoolType(previousPath)
        }

      }, [location]);
console.log('previousRoute ',previousRoute)

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleChange = (field, e) => {
        setFormValues({ ...formValues, [field]: e.target.value });
        setFormErrors({...formErrors, [field] :""})
    };


    const handleToggle = () => {
        setTermsAndCondition(!termsAndCondition)
    }

    const validateForm = () => {
        const errors = { email: '', password: '' };
        let isValid = true;

        if (!formValues.email) {
            errors.email = 'Email is required';
            isValid = false;
        } 

        if (!formValues.password) {
            errors.password = 'Password is required';
            isValid = false;
        } 

        setFormErrors(errors);
        return isValid;
    };

    const handleLogin = () => {
        if (validateForm()) {
            // Perform login action
            console.log('Form is valid. Submitting login.');
            Login()
        }
    };


    const sendOtp = async(userData) => {
        console.log('login ',userData)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/resendOtp`, {userId: userData._id, email: formValues.email, name: userData?.name })
            
            console.log(response);
            if(response?.data?.status == 'PENDING') {
                setToastStatus({color:'green',status:true,message:response.data.message,icon:'tick'})
                setIsLoading(false)
                setTimeout(()=>{
                    setToastStatus({color:'',status:false})
                    navigate('/otp')
                },3000)
            } else if(response?.data?.status == 'FAILED') {
                setToastStatus({color:'red',status:true,message:response.data.message,icon:'close'})
                setIsLoading(false)
                setTimeout(()=>{
                    setToastStatus({color:'',status:false})
                },5000)
            }
        } catch (error) {
            console.log('errror ',error);
        }
    }


    const Login = async () => {

        try {
            setIsLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, {email: formValues.email, password: formValues.password})
            console.log('resssssss ',response);
            if(response?.data?.status == 'success') {
                setToastStatus({color:'green',status:true,message:response.data.message,icon:'tick'})
                localStorage.setItem('student_token',response.data.access_token)
                if(response.data.access_token) {
                    localStorage.removeItem('admin_token')
                }
                localStorage.setItem('role_name',response.data.role_name)
                let userData = response.data.userData
                delete userData.password
                delete userData.confirm_password
                localStorage.setItem('student_data', JSON.stringify(userData))
                setIsLoading(false)
                setTimeout(()=>{
                    setToastStatus({color:'',status:false})
                    console.log('previousRoutes ',previousRoute)
                    if(previousSchoolType ? (previousSchoolType == 'school') : localStorage.getItem('schoolType') == 'school') {
                        console.log('schooo ')
                        navigate(location.state?.routes ? location.state?.routes : localStorage.getItem('schoolRoute'))
                    } else if(previousSchoolType ? (previousSchoolType == 'university') : localStorage.getItem('schoolType') == 'university') {
                        navigate(location.state?.routes ? location.state?.routes : localStorage.getItem('schoolRoute'))
                    } else {
                        navigate('/')
                    }
                },3000)
            } else if(response?.data?.status == 'failed') {
                setIsLoading(false)
                    setToastStatus({color:'RED',status:true,message:response.data.message,icon:'close'})
                    setTimeout(()=>{
                        setToastStatus({color:'',status:false})
                    },5000)
            }
        } catch (error) {
            console.log('error ',error);
            if(error?.response?.data?.status == 'failed') {
                if(error.response.data.message == 'Account not verified') {
                    setToastStatus({color:'RED',status:true,message:error.response.data.message,icon:'close'})
                    console.log('respodata ',error.response.data.userData)
                    setUserDetails(error.response.data.userData)
                    setTimeout(()=>{
                        setToastStatus({color:'',status:false})
                        sendOtp(error.response.data.userData)
                    },1500)
                } else {
                setToastStatus({color:'RED',status:true,message:error.response.data.message,icon:'close'})
                setIsLoading(false)
                setTimeout(()=>{
                    setToastStatus({color:'',status:false})
                },5000)
            }
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])


    return (
        <Header>
            <ToastAlert  message={toastStatus?.message} status={toastStatus?.status}  color={toastStatus?.color} icon={toastStatus?.icon} closeFunc={() => setToastStatus({color:'',status:false})} />  
            <Box sx={{padding: {xs:'4% 10%',sm:'4% 8%', md:'4% 4%'}, backgroundColor: '#F5DFBA', display: 'flex', justifyContent: 'center', flexDirection: "row", alignItems: "start", height:'100%' }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                    width: { xs: '100%', md: '50%' },
                    maxWidth:'400px',
                    backgroundColor: '#F7F7F7',
                    padding: '30px',
                    borderRadius: '15px ',
                    flex:1
                }}>
                    <Box sx={{ display: 'flex', gap: '90px', flexDirection: { xs: 'column', sm: 'row' }, }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '30px' }}>
                            <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: '25px', textAlign: 'left' }}>
                                Login
                            </RobotoTypo>

                            <Box sx={{ flex: '1' }}>
                                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                Email  <Box sx={{ color: '#FF202E', display: 'inline-block' }}> *</Box>
                                </RobotoTypo>
                                <TextField onChange={(e) => handleChange('email', e)} className={classes.TextField} variant="outlined" InputProps={{ border: 'none' }} sx={{ flex: '1', width: '100%', marginTop: "10px" }} />
                                <ErrTypo>{formErrors.email}</ErrTypo>
                            </Box>

                            <Box sx={{ flex: '1' }}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                Password <Box sx={{ color: '#FF202E', display: 'inline-block' }}> *</Box>
                            </RobotoTypo>
                            <Box sx={{ position: 'relative', flex: '1', width: '100%', marginTop: "10px", }}>
                                <TextField
                                    
                                    onChange={(e) => handleChange('password', e)}
                                    InputProps={{
                                        border: 'none',
                                        type: showPassword ? 'text' : 'password', // Toggle between text and password type
                                    }}
                                    className={classes.TextField} variant="outlined" sx={{ flex: 1, width: '100%' }} />
                                <IconButton className={classes.iconButton} sx={{ position: 'absolute', right: '20px', top: '15px' }} onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                <ErrTypo>{formErrors.password}</ErrTypo>
                            </Box>

                        </Box>

                        </Box>
                    </Box>


                    {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox checked={termsAndCondition} onChange={handleToggle} size="small" />    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '14px', textAlign: 'left' }}>
                           Remember this computer ?
                        </RobotoTypo>

                    </Box> */}

                    <Box sx={{ textAlign: 'center' }}>
                        <Button onClick={handleLogin} variant='contained' sx={{
                            width: '100%',
                            display:'flex',
                            justifyContent:'center',
                            borderRadius: '50px', textAlign: 'center', backgroundColor: '#177d84',
                            '&:hover': {
                                backgroundColor: '#177d84',
                            },
                        }} >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', padding: '6px 5px' }}>
                                <RobotoTypo sx={{ color: '#FFFFFF', letterSpacing: '0.02em', textTransform: 'none', lineHeight: '32px', fontWeight: '500', fontSize: { xs: 16, sm: 18 } }}>Login</RobotoTypo>
                            </Box>
                            {isLoading && <Box sx={{ display: 'flex', alignSelf:'flex-end',alignSelf:'center' , width:'30px' }}>
                                <CircularProgress size={30} sx={{color:'white'}}/>
                            </Box>}
                        </Button>
                    </Box>
                    {/* <Stack direction="row" justifyContent="end" alignItems="center" columnGap={2}>
                        <Button variant='text' sx={{color:'#003B5C'}}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '14px', textAlign: 'left', textTransform:'none' }}>
                            Forget Password
                            </RobotoTypo>
                        </Button>
                    </Stack> */}
                    <Box sx={{display:'flex', gap:2, alignItems:'center', alignSelf:'end'}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '14px', textAlign: 'left' }}>
                           Don't have an account yet ?
                        </RobotoTypo>

                        <Button onClick={() =>  navigate('/register')} variant='contained' sx={{
                           
                            borderRadius: '50px', textAlign: 'center', backgroundColor: '#ffc424',
                            '&:hover': {
                                backgroundColor: '#ffc424',
                            },
                        }} >
                            <Box sx={{ display: 'flex', alignItems: 'center',}}>
                                <RobotoTypo sx={{padding: '0px 14px', color: '#FFFFFF',textTransform: 'none',  fontWeight: '400', fontSize: '14px' }}> Register Now !</RobotoTypo>
                            </Box>
                        </Button>
                      
                    </Box>
                    
                </Box>

                

                {/* <Box sx={{
                    height:'auto',
                    backgroundColor: "#b1d0e0", display: 'flex',
                    flexDirection: 'column',
                    // alignItems: "center",
                    gap: '30px',
                    // width: { xs: '100%', md: '100%' },
                    backgroundColor: '#b1d0e0',
                    padding: '30px',
                    borderRadius: '0 15px 15px 0',
                    // height:'100%',
                    flex:1,
                }}>
                    <RobotoTypo sx={{ width: "fit-content", fontWeight: '400', borderRadius: "20px", backgroundColor: "#003b5c", color: 'white', fontSize: '16px', textTransform: "none", padding: '6px 14px' }}>
                        Other Login Method
                    </RobotoTypo>
                    <Box sx={{display:'flex', gap:'10px', flexWrap:'wrap'}}>
                        <Box sx={{display:'flex', justifyContent:'center', width:'100%', gap:5}}>
                            <Button variant="outlined" sx={{width:'50%', padding: '10px 14px', borderRadius:'20px', backgroundColor:'White', '&:hover': {backgroundColor:'white'}, '&.focus':{backgroundColor:'white'}}} startIcon={
                                <Box component="img" src={loginWeChat} sx={{ width: 40, height: 40 }} />}> <RobotoTypo sx={{textTransform:'none', fontWeight: '500', color: '#003B5C', fontSize: '14px', textAlign: 'left' }}>
                                WeChat
                             </RobotoTypo>
                            </Button>
                            <Button variant="outlined" sx={{width:'50%',  padding: '10px 14px', borderRadius:'20px', backgroundColor:'White', '&:hover': {backgroundColor:'white'}, '&.focus':{backgroundColor:'white'}}} startIcon={
                                <Box component="img" src={loginFB} sx={{ width: 50, height: 40 }} />}><RobotoTypo sx={{textTransform:'none', fontWeight: '500', color: '#003B5C', fontSize: '14px', textAlign: 'left' }}>
                                Facebook
                             </RobotoTypo>
                            </Button>
                        </Box>
                        <Box sx={{display:'flex', justifyContent:'center', width:'100%', gap:5}}>
                            <Button variant="outlined" sx={{width:'50%',  padding: '10px 14px', borderRadius:'20px', backgroundColor:'White', '&:hover': {backgroundColor:'white'}, '&.focus':{backgroundColor:'white'}}} startIcon={
                                <Box component="img" src={loginWP} sx={{ width: 50, height: 40 }} />}><RobotoTypo sx={{textTransform:'none', fontWeight: '500', color: '#003B5C', fontSize: '14px', textAlign: 'left' }}>
                                Whatsapp
                             </RobotoTypo>
                            </Button>
                            <Button variant="outlined" sx={{width:'50%',borderRadius:'20px',  padding: '10px 14px', backgroundColor:'White', '&:hover': {backgroundColor:'white'}, '&.focus':{backgroundColor:'white'}}} startIcon={
                                <Box component="img" src={loginLine} sx={{width: 50, height: 40}} />}><RobotoTypo sx={{textTransform:'none', fontWeight: '500', color: '#003B5C', fontSize: '14px', textAlign: 'left' }}>
                                Line
                             </RobotoTypo>
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex', gap:2, alignItems:'center', alignSelf:'end'}}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '14px', textAlign: 'left' }}>
                           Don't have an account yet ?
                        </RobotoTypo>

                        <Button onClick={() =>  navigate('/register')} variant='contained' sx={{
                           
                            borderRadius: '50px', textAlign: 'center', backgroundColor: '#ffc424',
                            '&:hover': {
                                backgroundColor: '#ffc424',
                            },
                        }} >
                            <Box sx={{ display: 'flex', alignItems: 'center',}}>
                                <RobotoTypo sx={{padding: '0px 14px', color: '#FFFFFF',textTransform: 'none',  fontWeight: '400', fontSize: '14px' }}> Register Now !</RobotoTypo>
                            </Box>
                        </Button>
                      
                    </Box>
                </Box> */}
            </Box >
            <Footer />
        </Header >

    )
}
