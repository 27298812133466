import React, { useState } from "react";
import Brand1 from "../../assets/png/brands/brand_1.png";
import Brand2 from "../../assets/png/brands/brand_2.png";
import Brand3 from "../../assets/png/brands/brand_3.png";
import Brand4 from "../../assets/png/brands/brand_4.png";
import Brand5 from "../../assets/png/brands/brand_5.png";
import Brand6 from "../../assets/png/brands/brand_6.png";
// import FlowDiagram from '../../assets/Home/flowDiagram.png';
import FlowDiagram from "../../assets/Home/Singapore flowchart.png";
import { Box, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RobotoTypo } from "../../utils/Typographies";
import ReactImageMagnify from "react-image-magnify";
import CloseIcon from "@mui/icons-material/Close";

const BrandImages = [Brand3, Brand5, Brand4, Brand1, Brand2, Brand6];

const FlowChart = () => {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(false);
  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setZoom(false);
  };
  const [open, setOpen] = useState(false);
  return (
    <Box sx={{ p: { xs: 4, lg: 6 } }}>
      <RobotoTypo
        sx={{
          textAlign: "center",
          fontSize: { xs: 20, sm: 24, lg: 32 },
          color: "#003B5C",
          fontWeight: 700,
        }}
      >
        {t("Home.flowChart.title")}
      </RobotoTypo>
      <Box
        component="img"
        src={FlowDiagram}
        alt={`singapore-flow-diagram`}
        sx={{
          cursor: "pointer",
          height: { xs: "300px", sm: "500px" },
          width: "100%",
          objectFit: "contain",
        }}
        onClick={handleMouseEnter}
      />
      <Dialog
        sx={{ width: "100%", height: "100%" }}
        maxWidth={"lg"}
        open={open}
      >
        <Box
          display={"flex"}
          sx={{ py: "10px", px: "30px" }}
          justifyContent={"flex-end"}
        >
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
            fontSize="large"
            sx={{ cursor: "pointer" }}
          />
        </Box>
        {/* <Box
          component="img"
          src={FlowDiagram}
          alt={`singapore-flow-diagram`}
          sx={{
            transform: zoom ? "scale(1.2)" : "scale(1)",
            cursor: "pointer",
          }}
          style={{
            height: "inherit",
            width: "inherit",
            objectFit: "contain",
          }}
          onClick={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        /> */}

        {/* <Box sx={{ width: 1200, height: { xs: "100%", sm: "100%" } }}> */}
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: "Example image",
              isFluidWidth: true,
              src: FlowDiagram,
            },
            largeImage: {
              src: FlowDiagram,
              width: 2500,
              height: 1000,
            },
            isHintEnabled: true,
            enlargedImagePosition: "over",
          }}
        />

        {/* </Box> */}
      </Dialog>
    </Box>
  );
};

export default FlowChart;
