import React from 'react'
import { Box, IconButton } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RightArrow } from '../../../../assets/StudyingAboard/RightSideArrow.svg';
import { useNavigate } from 'react-router-dom';

export default function PrimarySchoolRecommendation({data}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    console.log('schoolListss ',data)
    return (
        <Box sx={{
            display: 'flex', flexDirection: 'column', padding: '3% 4% 3% 4%', backgroundColor: '#F7F7F7', gap: '50px',
        }}>
            <Box sx={{ marginTop: '10px', alignSelf: 'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px' }
            } >
                <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '24px', lg: '24px' }, textAlign: 'left' }}>
                    {t('StudyingAboard.primarySchool.Recommendation', { returnObjects: true }).title}
                </RobotoTypo>
            </ Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '70px', justifyContent: 'center', width: '100%' }}>
                {data?.map((item, index) => (
                    <Box key={index} sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        boxShadow: '4px 4px 4px 0px #00000040', borderRadius: '30px', backgroundColor: 'white', position: 'relative', minWidth: '260px', width: { xs: '70%', sm: '40%', md: '40%', lg: '25%' }
                    }}>
                        <Box sx={{ padding: { xs: '25px 0 25px', lg: '25px 0 25px' }, margin: '0 auto', display: 'flex', gap: '20px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '85%' }}>
                            <Box sx={{ width: '100%',  minHeight:'150px', maxHeight:'150px' }}>
                                <Box component='img' src={`${process.env.REACT_APP_FILE_URL}${item.data.school_banner}`} alt="" sx={{ width: '100%', height:'100%', borderRadius:'10px' }} />
                            </Box>

                            <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: '#003B5C', fontSize: { xs: '20px', sm: '20px', }, alignSelf: 'flex-start' }}>
                                {/* {t('StudyingAboard.primarySchool.Recommendation.cards', { returnObjects: true })[index].title} */}
                                {!item?.data_en && item.data.school_name}
                            </RobotoTypo>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    gap: '3px',
                                    alignSelf: 'start',
                                    flex: '1'
                                }}
                            >
                                <RobotoTypo sx={{ fontWeight: '700', textAlign: 'left', color: '#003B5C', fontSize: { xs: '17px', sm: '18', md: '19px', lg: '21px' } }}>{item?.data_en && item.data.school_name}</RobotoTypo>
                                <TimesNewTypo sx={{ fontWeight: '700', textAlign: 'left', color: '#177D84', fontSize: { xs: '14px', sm: '16px', md: '17px', lg: '18px' } }}>{item?.data_en?.school_name}</TimesNewTypo>

                            </Box>
                            {/* position: 'absolute', bottom: '10px', right: '30px',  */}
                            <Box sx={{ alignSelf: 'end', position: 'absolute', bottom: '10px', right: '20px' }}>
                                {/* <Button sx={{
                                    backgroundColor: '#177D84', 
                                    border: '1px solid #177D84',  color: 'white', borderRadius: '50px', ":hover": {
                                        bgcolor: "#177D84",
                                        color: "",

                                    }
                                }}> */}
                                <IconButton
                                onClick={() => navigate(`/school/${item.id}`)}
                                sx={{
                                    backgroundColor: '#177D84', padding: '14px', ":hover": {
                                        bgcolor: "#177D84",

                                    }
                                }}> <RightArrow /> </IconButton>
                                {/* </Button> */}
                            </Box>
                        </Box>
                    </Box>
                ))
                }

            </Box >

        </Box >
    )
}
