import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Admin from "../Dashboard";


const rows = [
  { id: 1, title: "Title 1", description: "Description 1" },
  { id: 2, title: "Title 2", description: "Description 2" },
  { id: 3, title: "Title 3", description: "Description 3" },
  // Add more rows as needed
];

 const  HigherOfficial=()=> {
  const navigate = useNavigate();
  const [higherOfficialData,setHigherOfficialData] = useState([]);
  const getHeaderOfficialDetails=async()=>{
    try{
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getHeaderDetails`)
      if(response.status==200){
          setHigherOfficialData(response.data)
      }
      
    }
    catch(err){
      console.log(err)
    }

  }

  useEffect(()=>{
    getHeaderOfficialDetails()
  },[])
  const handleDelete = async(id) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/deleteHeaderOfficialById/${id}`,{
          headers: {
            Authorization: localStorage.getItem('admin_token')
          }
        });
        if(response.status == 200) {
          getHeaderOfficialDetails()
        }
      } catch (error) {
        if(error.response.status == 403) {
          localStorage.clear()
          navigate('/admin/login')
    }
      }
  }
  return (
    <Admin>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 1,
        }}
      >
        <Button
          component="span"s
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
          onClick={()=>{
            navigate("/admin/addHigherOfficial")
          }}
        >
          Add
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sno</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {higherOfficialData?.length != 0 &&
              higherOfficialData?.map((team, index) => (
                <TableRow key={team._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{team.en.name}</TableCell>
                  <TableCell>{team.en.role}</TableCell>
                  <TableCell>
                    <IconButton aria-label="delete">
                      <DeleteIcon onClick={()=>{
                        handleDelete(team._id)
                      }} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="edit">
                      <EditIcon onClick={()=>{
                        navigate(`/admin/EditHigherOfficial/${team._id}`)
                      }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Admin>
  );
}

export default HigherOfficial