import React from "react";


import LanguageHeader from "./LanguageHeader";
import LanguageBody from "./LanguageBody";
import SideOverlap from "../../../pages/sideOverlap";
import Header from "../../Header";
import Share from "../../Share";
import Footer from "../../Footer";

export default function LanguageCourse() {
  return (
    <>
      <Header/>
      <LanguageHeader />
      <LanguageBody />
      <Share/>
      <Footer/>
      <SideOverlap />
    </>
  );
}
