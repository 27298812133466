import React, { useState, useEffect } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel, Slider, Button, Grid, Stack, Divider, TextareaAutosize, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { RobotoTypo } from '../../../utils/Typographies'
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Hover1 } from '../../../assets/ContactUs/DateIcon.svg';
import { useTranslation } from 'react-i18next';
import { Grade, TroubleshootTwoTone } from '@mui/icons-material';

export default function SchoolApplicationForm3({ setFormPage, otherDetails, setOtherDetails, setIsFormValid }) {
    const { t } = useTranslation();
    const { health_history_Details, otherQuestions, american_school_sg } = otherDetails
    // const { have_physical_disability, ...rest } = health_history_Details

    const handleChange = (e, key) => {
        const { name, value } = e.target
        setOtherDetails((prev) => ({ ...prev, [key]: { ...prev[key], [name]: value } }))
    }

    const handleContinue = () => {
        setIsFormValid(prev => ({...prev, formThree: true, formFour: true}))
        setFormPage(4)
    }

    useEffect(() => {
        console.log(otherDetails);
    }, [otherDetails])


    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                py={1}
                sx={{
                    backgroundColor: "#e1e1e1",
                    borderBottom: "1px solid #E5E7EB",
                    borderTop: "1px solid #E5E7EB",
                }}
            >
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Development_health_history_Title', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.Development_health_history_1', { returnObjects: true })}  
                    </RobotoTypo>
                    <RadioGroup row name="have_physical_disability" value={health_history_Details?.have_physical_disability} onChange={(e) => { handleChange(e, "health_history_Details") }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {(health_history_Details?.have_physical_disability == 'true') &&
                        <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                            multiline
                            name="have_physical_disability_des" value={health_history_Details?.have_physical_disability_des} onChange={(e) => { handleChange(e, "health_history_Details") }}
                        />
                    }
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.Development_health_history_2', { returnObjects: true })}  
                    </RobotoTypo>
                    <RadioGroup row name="have_serious_hlth_prob" value={health_history_Details?.have_serious_hlth_prob} onChange={(e) => { handleChange(e, "health_history_Details") }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {(health_history_Details?.have_serious_hlth_prob == 'true') &&
                        <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                            multiline
                            name="have_serious_hlth_prob_des" value={health_history_Details?.have_serious_hlth_prob_des} onChange={(e) => { handleChange(e, "health_history_Details") }}
                        />
                    }
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        3. {t('ApplicationForm.Development_health_history_3', { returnObjects: true })}  
                    </RobotoTypo>
                    <RadioGroup row name="difficulty_In_physical_skills" value={health_history_Details?.difficulty_In_physical_skills} onChange={(e) => { handleChange(e, "health_history_Details") }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {(health_history_Details?.difficulty_In_physical_skills == 'true') &&
                        <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                            multiline
                            name="difficulty_In_physical_skills_des" value={health_history_Details?.difficulty_In_physical_skills_des} onChange={(e) => { handleChange(e, "health_history_Details") }}
                        />
                    }
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        4. {t('ApplicationForm.Development_health_history_4', { returnObjects: true })}  
                    </RobotoTypo>
                    <RadioGroup row name="had_trouble_paying_attention" value={health_history_Details?.had_trouble_paying_attention} onChange={(e) => { handleChange(e, "health_history_Details") }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {(health_history_Details?.had_trouble_paying_attention == 'true') &&
                        <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                            multiline
                            name="had_trouble_paying_attention_des" value={health_history_Details?.had_trouble_paying_attention_des} onChange={(e) => { handleChange(e, "health_history_Details") }}
                        />
                    }
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        5. {t('ApplicationForm.Development_health_history_5', { returnObjects: true })}  
                    </RobotoTypo>
                    <RadioGroup row name="have_allergies" value={health_history_Details?.have_allergies} onChange={(e) => { handleChange(e, "health_history_Details") }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {(health_history_Details?.have_allergies == 'true') &&
                        <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                            multiline
                            name="have_allergies_des" value={health_history_Details?.have_allergies_des} onChange={(e) => { handleChange(e, "health_history_Details") }}
                        />
                    }
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        6. {t('ApplicationForm.Development_health_history_6', { returnObjects: true })}  
                    </RobotoTypo>
                    <RadioGroup row name="Is_taking_any_medication" value={health_history_Details?.Is_taking_any_medication} onChange={(e) => { handleChange(e, "health_history_Details") }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {(health_history_Details?.Is_taking_any_medication == 'true') &&
                        <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                            multiline
                            name="Is_taking_any_medication_des" value={health_history_Details?.Is_taking_any_medication_des} onChange={(e) => { handleChange(e, "health_history_Details") }}
                        />
                    }
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        7. {t('ApplicationForm.Development_health_history_7', { returnObjects: true })}  
                    </RobotoTypo>
                    <RadioGroup row name="can_use_toilet_independently" value={health_history_Details?.can_use_toilet_independently} onChange={(e) => { handleChange(e, "health_history_Details") }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {(health_history_Details?.can_use_toilet_independently == 'true') &&
                        <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                            multiline
                            name="can_use_toilet_independently_des" value={health_history_Details?.can_use_toilet_independently_des} onChange={(e) => { handleChange(e, "health_history_Details") }}
                        />
                    }
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        8. {t('ApplicationForm.Development_health_history_8', { returnObjects: true })}  
                    </RobotoTypo>
                    <RadioGroup row name="can_wear_his_clothes" value={health_history_Details?.can_wear_his_clothes} onChange={(e) => { handleChange(e, "health_history_Details") }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {(health_history_Details?.can_wear_his_clothes == 'true') &&
                        <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                            multiline
                            name="can_wear_his_clothes_des" value={health_history_Details?.can_wear_his_clothes_des} onChange={(e) => { handleChange(e, "health_history_Details") }}
                        />
                    }
                </Grid>
            </Grid>
            <Stack justifyContent="center" px="16px" py={1} sx={{ backgroundColor: "#e1e1e1", borderBottom: "1px solid #E5E7EB", borderTop: "1px solid #E5E7EB", }} >
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Other_Questions', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.Other_Questions_1', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="like_most_in_shl" value={otherQuestions?.like_most_in_shl} onChange={(e) => { handleChange(e, "otherQuestions") }} />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.Other_Questions_2', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="fav_thing_todo_aft_cls" value={otherQuestions?.fav_thing_todo_aft_cls} onChange={(e) => { handleChange(e, "otherQuestions") }} />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        3. {t('ApplicationForm.Other_Questions_3', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="like_reading" value={otherQuestions?.like_reading} onChange={(e) => { handleChange(e, "otherQuestions") }} />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        4. {t('ApplicationForm.Other_Questions_4', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="sought_additional_support" value={otherQuestions?.sought_additional_support} onChange={(e) => { handleChange(e, "otherQuestions") }} />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        5. {t('ApplicationForm.Other_Questions_5', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="attended_special_classes" value={otherQuestions?.attended_special_classes} onChange={(e) => { handleChange(e, "otherQuestions") }} />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        6. {t('ApplicationForm.Other_Questions_6', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="hav_social_dev_prob" value={otherQuestions?.hav_social_dev_prob} onChange={(e) => { handleChange(e, "otherQuestions") }} />
                </Grid>
            </Grid>
            <Stack justifyContent="center" px="16px" py={1} sx={{ backgroundColor: "#e1e1e1", borderBottom: "1px solid #E5E7EB", borderTop: "1px solid #E5E7EB", }} >
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Applicants_American_School_Singapore', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Applicant_Father', { returnObjects: true })}  
                    </RobotoTypo><br />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.favorite_hobby', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="father_fav_hobby" value={american_school_sg?.father_fav_hobby} onChange={(e) => { handleChange(e, "american_school_sg") }} />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.Is_interesting_you’d_like_us_to_know', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="father_intersting_thing" value={american_school_sg?.father_intersting_thing} onChange={(e) => { handleChange(e, "american_school_sg") }} />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Applicant_Mother', { returnObjects: true })}  
                    </RobotoTypo><br />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.favorite_hobby', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="mother_fav_hobby" value={american_school_sg?.mother_fav_hobby} onChange={(e) => { handleChange(e, "american_school_sg") }} />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.Is_interesting_you’d_like_us_to_know', { returnObjects: true })}  
                    </RobotoTypo><br />
                    <TextField name="mother_intersting_thing" value={american_school_sg?.mother_intersting_thing} onChange={(e) => { handleChange(e, "american_school_sg") }} />
                </Grid>
            </Grid>
            <Box display='flex' justifyContent="space-between" padding='2% 3%' sx={{ borderTop: "1px solid #E5E7EB", }}>
                <Button onClick={() => { setFormPage(2) }} variant='Standard'>Back</Button>
                <Button onClick={handleContinue} variant='Standard'>Continue</Button>
            </Box>
        </>
    )
}
