import React,{useEffect, useState} from 'react'
import {Box, Button, Grid, IconButton, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Admin from '../Dashboard';
import { RobotoTypo } from '../../../utils/Typographies';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import  axios  from 'axios';
import { useNavigate } from 'react-router-dom';

const rows = [
    { id: 1, title: 'Title 1', description: 'Description 1' },
    { id: 2, title: 'Title 2', description: 'Description 2' },
    { id: 3, title: 'Title 3', description: 'Description 3' },
    // Add more rows as needed
  ];



export default function CarouselList() {
    const navigate = useNavigate()
    const [carouselsList, setCarouselsList] = useState([])

    const getCarousel = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getCarousel`);
              console.log('response get ',response)
              if(response.status == 200) {
                setCarouselsList(response.data)
              } else if(response.status == 403) {
                localStorage.clear()
                navigate('/admin/login')
          }
        } catch(error) {
            console.log('er ',error)
        }
      }

      useEffect(() => {
        getCarousel()
      },[])
      console.log('carouselsList ',carouselsList)

      const handleDelete = async(id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/deleteCarouselById/${id}`,{
              headers: {
                Authorization:localStorage.getItem('admin_token')
              }
            });
            if(response.status == 200) {
                getCarousel()
            } 
          } catch (error) {
            if(error.response.status == 403) {
              localStorage.clear()
              navigate('/admin/login')
            }
          }
      }
      const handleEdit = (id) => {
        navigate('/admin/Carousel/'+id)
      }

  return (
    <Admin>
        <Box sx={{display:'flex',justifyContent:'flex-end', width:'100%',mb:1,}}>
            <Button component="span" onClick={() => navigate('/admin/carousel/add')} variant="contained" sx={{ alignSelf:'center', mt:1,width:'fit-content', backgroundColor:'#177D84','&:hover': {backgroundColor: '#177D84'},}}>
                Add
            </Button>
        </Box>
        
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sno</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>url</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {carouselsList.map((row, index) => (
            <TableRow key={row.id}>
                <TableCell>{index+1}</TableCell>
              <TableCell>{row.en.title}</TableCell>
              <TableCell>{row.en.description}</TableCell>
              <TableCell>{row.en.url}</TableCell>
              <TableCell>
                <IconButton aria-label="delete" onClick={() => handleDelete(row._id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton aria-label="edit" onClick={() => handleEdit(row._id)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Admin>
  )
}
