import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { RobotoTypo } from '../../../utils/Typographies'
import SchoolApplicationForm1 from './SchoolApplicationForm1'
import SchoolApplicationForm2 from './SchoolApplicationForm2'
import SchoolApplicationForm3 from './SchoolApplicationForm3'
import Stepper from "./Stepper"
import Reivew from './Reivew'
import axios from 'axios'
import ToastAlert from '../../../utils/Alert';
import SuccessApplied from '../ApplicationSubmitted/SucessApplied'

export default function SchoolApplicationForm() {
    const { id } = useParams()
    const [schoolName, setSchoolName] = useState('')
    const [formPage, setFormPage] = useState(1)
    const [toastStatus,setToastStatus] = useState({color:'',status:false,message:'',icon:''})
    const [isFormValid, setIsFormValid] = useState({formOne: false, formTwo: false, formThree: false, formFour: false})
    const [form1Errors, setForm1Errors] = useState({name: "",passport_name:"", country_of_birth:"", residential_address:"", Is_lived_in_pastfiveyears:"", additional_info:"", language_levels:"" })
    const [form2Errors, setForm2Errors] = useState({fatherDetails: {name:"",avg_month_sal:"", current_deposit:"", phone:"" }, motherDetails: {name:"",avg_month_sal:"", current_deposit:"", phone:"" }  })
    const [isAppliedSuccess, setIsAppliedSuccess] = useState(false)
    const loggedinStudentData = localStorage.getItem('student_data') ? JSON.parse(localStorage.getItem('student_data')) : null
    console.log('schoolName ',schoolName)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [formPage])

    const initialpersonalDetails = {
        name: "",
        passport_name: "",
        religious_belief: "",
        country_of_birth: "",
        school_name: schoolName,
        recent_grade_attended: "",
        expected_enrolment_date: "",
        grade_applying_for: "",
        school_address: "",
        school_contact: "",
        school_countryCode: "",
        school_phone: "",
        school_email: "",
        teacher_name: "",
        teacher_email: "",
        residential_address: "",
        Is_lived_in_pastfiveyears: "",
        additional_info: "",
        primary_languages: [{ name: "", level: "" }],
        language_levels: [{ name: "Chinese", speaking: "", reading: "", writing: "" }, { name: "English", speaking: "", reading: "", writing: "" }],
        years_learned_english: "",
        kind_of_person: [{ name1: "Shy", name2: "Outgoing/Social", value: "" }, { name1: "Calm", name2: "Excited" }, { name1: "Cautious", name2: "Risky" }, { name1: "Submissive", name2: "Determined" }, { name1: "Distracted", name2: "Centralized" }, { name1: "Quiet", name2: "Talkative" }, { name1: "Adaptable", name2: "Keen" }, { name1: "Thoughtful", name2: "Impulsive" }, { name1: "Independent", name2: "Dependent" }, { name1: "Flexible", name2: "Rigid" }]
    }

    const initialfamilyDetails = {
        fatherDetails: {
            name: "",
            avg_month_sal: "",
            current_deposit: "",
            contact: "",
            country_code: "",
            phone: "",
            email: "",
            high_edu_level: "",
            occupation_type: "",
            position: "",
            company_name: "",
            company_country_code: "",
            company_contact: "",
            company_phone: "",
            company_address: "",
            Is_company_US: ""
        },
        motherDetails: {
            name: "",
            avg_month_sal: "",
            current_deposit: "",
            contact: "",
            country_code: "",
            phone: "",
            email: "",
            high_edu_level: "",
            occupation_type: "",
            position: "",
            company_name: "",
            company_country_code: "",
            company_contact: "",
            company_phone: "",
            company_address: "",
            Is_company_US: ""
        },
        SiblingsInfo: [{
            name: "",
            passport_name: "",
            relationship: "",
            dob: "",
            nationality: ""
        }, {
            name: "",
            passport_name: "",
            relationship: "",
            dob: "",
            nationality: ""
        }],
        grandmotherInfo: {
            name: "",
            passport_name: "",
            relationship: "",
            dob: "",
            nationality: ""
        }
    }

    const initialotherDetails = {
        health_history_Details: {
            have_physical_disability: "",
            have_physical_disability_des: "",
            have_serious_hlth_prob: "",
            have_serious_hlth_prob_des: "",
            difficulty_In_physical_skills: "",
            difficulty_In_physical_skills_des: "",
            had_trouble_paying_attention: "",
            had_trouble_paying_attention_des: "",
            have_allergies: "",
            have_allergies_des: "",
            Is_taking_any_medication: "",
            Is_taking_any_medication_des: "",
            can_use_toilet_independently: "",
            can_use_toilet_independently_des: "",
            can_wear_his_clothes: "",
            can_wear_his_clothes_des: ""
        },
        otherQuestions: {
            like_most_in_shl: "",
            fav_thing_todo_aft_cls: "",
            like_reading: "",
            sought_additional_support: "",
            attended_special_classes: "",
            hav_social_dev_prob: ""
        },
        american_school_sg: {
            father_fav_hobby: "",
            father_intersting_thing: "",
            mother_fav_hobby: "",
            mother_intersting_thing: "",
        }
    }


    const [personalDetails, setPersonalDetails] = useState(initialpersonalDetails)

    const [familyDetails, setFamilyDetails] = useState(initialfamilyDetails)

    const [otherDetails, setOtherDetails] = useState(initialotherDetails)
console.log('personalDetails ',personalDetails)

    const SubmitData = async () => {
        if(validationForm1()) {
            setFormPage(1)
            return
        } else if(validationForm2()) {
            setFormPage(2)
            return
        }
        const StudentDetails = {
            user_id: loggedinStudentData?._id,
            institute_id: id,
            type_id: "school",
            personalDetails: {
                name: personalDetails?.name,
                passport_name: personalDetails?.passport_name,
                religious_belief: personalDetails?.religious_belief,
                country_of_birth: personalDetails?.country_of_birth,
                school_name: schoolName,
                recent_grade_attended: personalDetails?.recent_grade_attended,
                expected_enrolment_date: personalDetails?.expected_enrolment_date,
                grade_applying_for: personalDetails?.grade_applying_for,
                school_address: personalDetails?.school_address,
                school_contact: personalDetails?.school_contact,
                school_countryCode: personalDetails?.school_countryCode,
                school_phone: personalDetails?.school_phone,
                school_email: personalDetails?.school_email,
                teacher_name: personalDetails?.teacher_name,
                teacher_email: personalDetails?.teacher_email,
                residential_address: personalDetails?.residential_address,
                Is_lived_in_pastfiveyears: personalDetails?.Is_lived_in_pastfiveyears,
                additional_info: personalDetails?.additional_info,
                primary_languages: personalDetails?.primary_languages,
                language_levels: personalDetails?.language_levels,
                years_learned_english: personalDetails?.years_learned_english,
                kind_of_person: personalDetails?.kind_of_person,
            },
            familyDetails: {
                fatherDetails: {
                    name: familyDetails?.fatherDetails?.name,
                    avg_month_sal: familyDetails?.fatherDetails?.avg_month_sal,
                    current_deposit: familyDetails?.fatherDetails?.current_deposit,
                    contact: familyDetails?.fatherDetails?.contact,
                    country_code: familyDetails?.fatherDetails?.country_code,
                    phone: familyDetails?.fatherDetails?.phone,
                    email: familyDetails?.fatherDetails?.email,
                    high_edu_level: familyDetails?.fatherDetails?.high_edu_level,
                    occupation_type: familyDetails?.fatherDetails?.occupation_type,
                    position: familyDetails?.fatherDetails?.position,
                    company_name: familyDetails?.fatherDetails?.company_name,
                    company_country_code: familyDetails?.fatherDetails?.company_country_code,
                    company_contact: familyDetails?.fatherDetails?.company_contact,
                    company_phone: familyDetails?.fatherDetails?.company_phone,
                    company_address: familyDetails?.fatherDetails?.company_address,
                    Is_company_US: familyDetails?.fatherDetails?.Is_company_US
                },
                motherDetails: {
                    name: familyDetails?.motherDetails?.name,
                    avg_month_sal: familyDetails?.motherDetails?.avg_month_sal,
                    current_deposit: familyDetails?.motherDetails?.current_deposit,
                    contact: familyDetails?.motherDetails?.contact,
                    country_code: familyDetails?.motherDetails?.country_code,
                    phone: familyDetails?.motherDetails?.phone,
                    email: familyDetails?.motherDetails?.email,
                    high_edu_level: familyDetails?.motherDetails?.high_edu_level,
                    occupation_type: familyDetails?.motherDetails?.occupation_type,
                    position: familyDetails?.motherDetails?.position,
                    company_name: familyDetails?.motherDetails?.company_name,
                    company_country_code: familyDetails?.motherDetails?.company_country_code,
                    company_contact: familyDetails?.motherDetails?.company_contact,
                    company_phone: familyDetails?.motherDetails?.company_phone,
                    company_address: familyDetails?.motherDetails?.company_address,
                    Is_company_US: familyDetails?.motherDetails?.Is_company_US
                },
                SiblingsInfo: familyDetails?.SiblingsInfo,
                grandmotherInfo: {
                    name: familyDetails?.grandmotherInfo?.name,
                    passport_name: familyDetails?.grandmotherInfo?.passport_name,
                    relationship: familyDetails?.grandmotherInfo?.relationship,
                    dob: familyDetails?.grandmotherInfo?.dob,
                    nationality: familyDetails?.grandmotherInfo?.nationality
                },
            },
            otherDetails: {
                health_history_Details: {
                    have_physical_disability: otherDetails?.health_history_Details?.have_physical_disability,
                    have_physical_disability_des: otherDetails?.health_history_Details?.have_physical_disability_des,
                    have_serious_hlth_prob: otherDetails?.health_history_Details?.have_serious_hlth_prob,
                    have_serious_hlth_prob_des: otherDetails?.health_history_Details?.have_serious_hlth_prob_des,
                    difficulty_In_physical_skills: otherDetails?.health_history_Details?.difficulty_In_physical_skills,
                    difficulty_In_physical_skills_des: otherDetails?.health_history_Details?.difficulty_In_physical_skills_des,
                    had_trouble_paying_attention: otherDetails?.health_history_Details?.had_trouble_paying_attention,
                    had_trouble_paying_attention_des: otherDetails?.health_history_Details?.had_trouble_paying_attention_des,
                    have_allergies: otherDetails?.health_history_Details?.have_allergies,
                    have_allergies_des: otherDetails?.health_history_Details?.have_allergies_des,
                    Is_taking_any_medication: otherDetails?.health_history_Details?.Is_taking_any_medication,
                    Is_taking_any_medication_des: otherDetails?.health_history_Details?.Is_taking_any_medication_des,
                    can_use_toilet_independently: otherDetails?.health_history_Details?.can_use_toilet_independently,
                    can_use_toilet_independently_des: otherDetails?.health_history_Details?.can_use_toilet_independently_des,
                    can_wear_his_clothes: otherDetails?.health_history_Details?.can_wear_his_clothes,
                    can_wear_his_clothes_des: otherDetails?.health_history_Details?.can_wear_his_clothes_des
                },
                otherQuestions: {
                    like_most_in_shl: otherDetails?.otherQuestions?.like_most_in_shl,
                    fav_thing_todo_aft_cls: otherDetails?.otherQuestions?.fav_thing_todo_aft_cls,
                    like_reading: otherDetails?.otherQuestions?.like_reading,
                    sought_additional_support: otherDetails?.otherQuestions?.sought_additional_support,
                    attended_special_classes: otherDetails?.otherQuestions?.attended_special_classes,
                    hav_social_dev_prob: otherDetails?.otherQuestions?.hav_social_dev_prob
                },
                american_school_sg: {
                    father_fav_hobby: otherDetails?.american_school_sg?.father_fav_hobby,
                    father_intersting_thing: otherDetails?.american_school_sg?.father_intersting_thing,
                    mother_fav_hobby: otherDetails?.american_school_sg?.mother_fav_hobby,
                    mother_intersting_thing: otherDetails?.american_school_sg?.mother_intersting_thing,
                }
            }
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/addStudentApplication`, StudentDetails)
            console.log(response);
            if (response.status == 201) {
                setToastStatus({color:'green',status:true,message:response.data,icon:'tick'})
                setIsAppliedSuccess(true)
                setTimeout(()=>{
                    setToastStatus({color:'',status:false})
                    setPersonalDetails(initialpersonalDetails)
                    setFamilyDetails(initialfamilyDetails)
                    setOtherDetails(initialotherDetails)
                    setFormPage(1)
                },3000)
              
            }
        } catch (error) {
            console.log(error);
            setToastStatus({color:'red',status:true,message:'something wrong',icon:'close'})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                setIsAppliedSuccess(false)
            },3000)
        }
    }

    const getSchooName = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getSchoolName/${id}`)
            if (response.status == 200) {
               setSchoolName(response.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validationForm1 = () => {
        let isErrors = []
        if(personalDetails.name == "") {
            setForm1Errors((prev) => ({...prev, name:"Please Enter Name"}))
            isErrors.push(true)
        } else {
            setForm1Errors((prev) => ({...prev, name:""}))
            isErrors.push(false)
        }
        if(personalDetails.passport_name == "") {
            setForm1Errors((prev) => ({...prev, passport_name:"Please Enter PassportName"}))
            isErrors.push(true)
        } else {
            setForm1Errors((prev) => ({...prev, passport_name:""}))
            isErrors.push(false)
        }
        if(personalDetails.country_of_birth == "") {
            setForm1Errors((prev) => ({...prev, country_of_birth:"Please Enter Country of Birth"}))
            isErrors.push(true)
        } else {
            setForm1Errors((prev) => ({...prev, country_of_birth:""}))
            isErrors.push(false)
        }
        if(personalDetails.residential_address == "") {
            setForm1Errors((prev) => ({...prev, residential_address:"Please Enter Residential Address"}))
            isErrors.push(true)
        } else {
            setForm1Errors((prev) => ({...prev, residential_address:""}))
            isErrors.push(false)
        }
        if(personalDetails.Is_lived_in_pastfiveyears == "") {
            setForm1Errors((prev) => ({...prev, Is_lived_in_pastfiveyears:"Please Choose Yes / No"}))
            isErrors.push(true)
        } else {
            setForm1Errors((prev) => ({...prev, Is_lived_in_pastfiveyears:""}))
            isErrors.push(false)
        }
        if(personalDetails.Is_lived_in_pastfiveyears != 'true' && personalDetails.additional_info == "") {
            setForm1Errors((prev) => ({...prev, additional_info:"Please Describe Info"}))
            isErrors.push(true)
        } else {
            setForm1Errors((prev) => ({...prev, additional_info:""}))
            isErrors.push(false)
        }
        const isError = isErrors.includes(true)
        console.log('true ',isError)
        return isError
    }

    const validationForm2 = () => {
        let isErrors = []
        
        if (familyDetails.fatherDetails.name === "") {
            setForm2Errors((prev) => ({...prev,
            fatherDetails: {
                ...prev.fatherDetails,
                name: "Please Enter Name"
            }
            }));
            isErrors.push(true);
        } else {
            setForm2Errors((prev) => ({...prev,
            fatherDetails: {
                ...prev.fatherDetails,
                name: ""
            }
            }));
            isErrors.push(false);
        }
        if (familyDetails.fatherDetails.avg_month_sal === "") {
            setForm2Errors((prev) => ({...prev,
            fatherDetails: {
                ...prev.fatherDetails,
                avg_month_sal: "Please Enter Average Salary"
            }
            }));
            isErrors.push(true);
        } else {
            setForm2Errors((prev) => ({...prev,
            fatherDetails: {
                ...prev.fatherDetails,
                avg_month_sal: ""
            }
            }));
            isErrors.push(false);
        }
        if (familyDetails.fatherDetails.current_deposit === "") {
            setForm2Errors((prev) => ({...prev,
            fatherDetails: {
                ...prev.fatherDetails,
                current_deposit: "Please Current Deposit"
            }
            }));
            isErrors.push(true);
        } else {
            setForm2Errors((prev) => ({...prev,
            fatherDetails: {
                ...prev.fatherDetails,
                current_deposit: ""
            }
            }));
            isErrors.push(false);
        }
        if (familyDetails.fatherDetails.phone === "") {
            setForm2Errors((prev) => ({...prev,
                fatherDetails: {
                ...prev.fatherDetails,
                phone: "Please Enter Phone Number"
            }
            }));
            isErrors.push(true);
        } else {
            setForm2Errors((prev) => ({...prev,
                fatherDetails: {
                ...prev.fatherDetails,
                phone: ""
            }
            }));
            isErrors.push(false);
        }

        if (familyDetails.motherDetails.name === "") {
            setForm2Errors((prev) => ({...prev,
                motherDetails: {
                ...prev.motherDetails,
                name: "Please Enter Name"
            }
            }));
            isErrors.push(true);
        } else {
            setForm2Errors((prev) => ({...prev,
                motherDetails: {
                ...prev.motherDetails,
                name: ""
            }
            }));
            isErrors.push(false);
        }
        if (familyDetails.motherDetails.avg_month_sal === "") {
            setForm2Errors((prev) => ({...prev,
                motherDetails: {
                ...prev.motherDetails,
                avg_month_sal: "Please Enter Average Salary"
            }
            }));
            isErrors.push(true);
        } else {
            setForm2Errors((prev) => ({...prev,
                motherDetails: {
                ...prev.motherDetails,
                avg_month_sal: ""
            }
            }));
            isErrors.push(false);
        }
        if (familyDetails.motherDetails.current_deposit === "") {
            setForm2Errors((prev) => ({...prev,
                motherDetails: {
                ...prev.motherDetails,
                current_deposit: "Please Enter Current Deposit"
            }
            }));
            isErrors.push(true);
        } else {
            setForm2Errors((prev) => ({...prev,
                motherDetails: {
                ...prev.motherDetails,
                current_deposit: ""
            }
            }));
            isErrors.push(false);
        }
        if (familyDetails.motherDetails.phone === "") {
            setForm2Errors((prev) => ({...prev,
                motherDetails: {
                ...prev.motherDetails,
                phone: "Please Enter Phone Number"
            }
            }));
            isErrors.push(true);
        } else {
            setForm2Errors((prev) => ({...prev,
                motherDetails: {
                ...prev.motherDetails,
                phone: ""
            }
            }));
            isErrors.push(false);
        }
        
        const isError = isErrors.includes(true)
        console.log('true ',isError)
        return isError
    }

    useEffect(() => {
        getSchooName()
    },[])

    console.log('shool ',schoolName)

    return (
        <>
            <ToastAlert  message={toastStatus?.message} status={toastStatus?.status}  color={toastStatus?.color} icon={toastStatus?.icon} closeFunc={() => setToastStatus({color:'',status:false})} />  
            <RobotoTypo sx={{ my: 3, textAlign: 'center', fontWeight: '500', color: '#003B5C', fontSize: '24px' }}>Applicant Information
            </RobotoTypo>
            <Box sx={{ backgroundColor: "#F7F7F7", margin: { md: '0 8%', xs: '0 2%' }, borderRadius: '20px' }}>
            <Stepper isFormValid={isFormValid} formPage={formPage} setFormPage={setFormPage} />
                    {formPage == 1 && <SchoolApplicationForm1 form1Errors={form1Errors} setForm1Errors={setForm1Errors} validationForm1={validationForm1} setIsFormValid={setIsFormValid} setFormPage={setFormPage} personalDetails={personalDetails} setPersonalDetails={setPersonalDetails} />}
                    {formPage == 2 && <SchoolApplicationForm2 form2Errors={form2Errors} setForm2Errors={setForm2Errors} validationForm2={validationForm2} setIsFormValid={setIsFormValid} setFormPage={setFormPage} familyDetails={familyDetails} setFamilyDetails={setFamilyDetails} />}
                    {formPage == 3 && <SchoolApplicationForm3 setIsFormValid={setIsFormValid} setFormPage={setFormPage} otherDetails={otherDetails} setOtherDetails={setOtherDetails} />}
                    {formPage == 4 && <Reivew setFormPage={setFormPage} schoolName={schoolName} personalDetails={personalDetails} familyDetails={familyDetails} otherDetails={otherDetails} SubmitData={SubmitData} />}
            </Box>
            {/* {!isAppliedSuccess? (<>
                <RobotoTypo sx={{ my: 3, textAlign: 'center', fontWeight: '500', color: '#003B5C', fontSize: '24px' }}>Applicant Information
                </RobotoTypo>
                {schoolName != '' && <Box sx={{ backgroundColor: "#F7F7F7", margin: { md: '0 8%', xs: '0 2%' }, borderRadius: '20px' }}>

                    <Stepper isFormValid={isFormValid} formPage={formPage} setFormPage={setFormPage} />
                    {formPage == 1 && <SchoolApplicationForm1 form1Errors={form1Errors} setForm1Errors={setForm1Errors} validationForm1={validationForm1} setIsFormValid={setIsFormValid} setFormPage={setFormPage} personalDetails={personalDetails} setPersonalDetails={setPersonalDetails} />}
                    {formPage == 2 && <SchoolApplicationForm2 form2Errors={form2Errors} setForm2Errors={setForm2Errors} validationForm2={validationForm2} setIsFormValid={setIsFormValid} setFormPage={setFormPage} familyDetails={familyDetails} setFamilyDetails={setFamilyDetails} />}
                    {formPage == 3 && <SchoolApplicationForm3 setIsFormValid={setIsFormValid} setFormPage={setFormPage} otherDetails={otherDetails} setOtherDetails={setOtherDetails} />}
                    {formPage == 4 && <Reivew setFormPage={setFormPage} schoolName={schoolName} personalDetails={personalDetails} familyDetails={familyDetails} otherDetails={otherDetails} SubmitData={SubmitData} />}
                </Box>}
            </>):(
                <SuccessApplied  schoolId={id} schoolPersonalDetails={personalDetails}  pageName='school' />
            )      }           */}
        </>
    )
}
