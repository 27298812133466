import React from 'react'
import Header from '../../Header'
import ChildCareHeader from './ChildCareHeader'
import ChildCareBody from './ChildCareBody'
import Share from '../../Share'
import Footer from '../../Footer'
import SideOverlap from '../../../pages/sideOverlap'

const ChildCare = () => {
  return (
   <>
     <Header />
            <ChildCareHeader/>
            <ChildCareBody/>
            <Share />
            <Footer />
            <SideOverlap/></>
  )
}

export default ChildCare