import React, { useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { RobotoTypo } from "../utils/Typographies";
import { ReactComponent as WhatsappIcon } from "../assets/svg/Share/Whatsapp.svg";
import { ReactComponent as InstagramIcon } from "../assets/svg/Share/Instagram.svg";
import { ReactComponent as Xiaohongshu } from "../assets/svg/Share/xiaohongshu.svg";
import { ReactComponent as LineIcon } from "../assets/svg/Share/Line.svg";
import { ReactComponent as MailIcon } from "../assets/svg/Share/Mail.svg";
import { ReactComponent as FaceBookIcon } from "../assets/svg/Share/Facebook.svg";
import { ReactComponent as KakaoTalkIcon } from "../assets/svg/Share/kakaotalk.svg";
import { ReactComponent as Tiktok } from "../assets/svg/Share/Tiktok.svg";
import { useTranslation } from "react-i18next";
import MailDialogBox from "./DialogComponents/MailDialogBox";

export default function Share() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showSocialButtons, setShowSocialButtons] = useState(false);
  const companyUrl = "https://dredugroup.com/";

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(companyUrl)
        .then(() => {
          console.log("URL copied to clipboard");
          setShowSocialButtons(true);
        })
        .catch((error) => {
          console.error("Error copying URL:", error);
          fallbackCopyTextToClipboard();
        });
    } else {
      fallbackCopyTextToClipboard();
    }
  };

  const fallbackCopyTextToClipboard = () => {
    const textarea = document.createElement("textarea");
    textarea.value = companyUrl;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    console.log("URL copied to clipboard using fallback");
    setShowSocialButtons(true);
  };

  const shareOnSocial = async (platform) => {
    await copyToClipboard();
    let url;

    switch (platform) {
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          companyUrl
        )}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          companyUrl
        )}`;
        break;
      case "line":
        url = `https://social-plugins.line.me/shareArticle?url=${encodeURIComponent(
          companyUrl
        )}`;
        break;

      default:
        return;
    }
    window.open(url, "_blank");
  };

  return (
    <>
      {/* <MailDialogBox open={open} setOpen={setOpen} />
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <RobotoTypo
          sx={{
            fontWeight: "700",
            color: "#003B5C",
            fontSize: { xs: "18px", sm: "20px", md: "22px", lg: "22px" },
            textAlign: "left",
          }}
        >
          {t("Share", { returnObjects: true }).title}
        </RobotoTypo>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <IconButton
            onClick={() => {
              window.open("https://wa.me/qr/T4B5Z7QJVR4BE1", "_blank");
            }}
          >
            <WhatsappIcon />
          </IconButton>

          <IconButton>
            <LineIcon
              onClick={() => {
                window.open("https://line.me/ti/p/5HJmiIyCHu", "_blank");
              }}
            />
          </IconButton>
          <IconButton onClick={() => setOpen(true)}>
            <MailIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(
                "https://u.wechat.com/IGSdRVR8p6ehEfMw6mlav9Q",
                "_blank"
              );
            }}
          >
            <WeChatIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(
                "https://www.facebook.com/SingaporeCustodianServices",
                "_blank"
              );
            }}
          >
            <FaceBookIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(
                "https://www.tiktok.com/@singaporedr2023?_t=8lLSmptEkuP&_r=1",
                "_blank"
              );
            }}
          >
            <Tiktok />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(
                "https://www.instagram.com/drmedia_singapore/",
                "_blank"
              );
            }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(
                "https://www.xiaohongshu.com/user/profile/6202f9f0000000001000f39",
                "_blank"
              );
            }}
          >
            <Xiaohongshu />
          </IconButton>
        </Box>
      </Box> */}
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <RobotoTypo sx={{ fontWeight: "600", fontSize: { sm: "20px" } }}>
          {t("Share", { returnObjects: true }).title}
        </RobotoTypo>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <IconButton onClick={() => shareOnSocial("facebook")}>
            <FaceBookIcon />
          </IconButton>
          <IconButton onClick={() => shareOnSocial("line")}>
            <LineIcon />
          </IconButton>

          <IconButton onClick={() => shareOnSocial("whatsapp")}>
            <WhatsappIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
