import React, { useState, useEffect } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel, Button, Divider, Stack, Slider, Grid, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material'
import { ErrTypo, RobotoTypo } from '../../../utils/Typographies'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import { customSlider } from "../../../utils/theme"


export default function SchoolApplicationForm1({ setFormPage, setPersonalDetails, personalDetails, setIsFormValid, validationForm1, form1Errors, setForm1Errors }) {
    const { t } = useTranslation();
    const { name, passport_name, religious_belief, country_of_birth, school_name, recent_grade_attended, expected_enrolment_date, grade_applying_for,
        school_address, school_phone, school_contact, school_email, teacher_name, teacher_email, residential_address, Is_lived_in_pastfiveyears, additional_info,
        primary_languages, language_levels, years_learned_english, kind_of_person } = personalDetails
        console.log('personalDetails ',personalDetails)
    
console.log('form1Errors ',form1Errors)


    const handleContinue = () => {
        const isValid = validationForm1()
        console.log('isValid ',isValid)
        if(!isValid) {
            setIsFormValid(prev => ({...prev, formOne: true}))
            setFormPage(2)
        }
    }

    const handleChange = (e, key, index) => {
        const { name, value } = e.target
        setForm1Errors(prev => ({...prev, [name]: ""}))
        if ((key == 'primary_languages') || (key == 'language_levels') || (key == 'kind_of_person')) {
            setPersonalDetails((prev) => {
                const data = prev[key].map((itm, ind) => {
                    if (ind == index) {
                        return { ...itm, [name]: value }
                    }
                    return itm
                })
                return { ...prev, [key]: data }
            })
        } else {
            setPersonalDetails((prev) => ({ ...prev, [name]: value }))
        }
    }

    const handleMobileInput = (value, country) => {
        setPersonalDetails((prev) => ({
            ...prev,
            school_contact: value,
            school_countryCode: `+${country.dialCode}`,
            school_phone: value.slice(country.dialCode.length),
        }))

    }

    const handleDeletelanguages = (index) => {
        const data = primary_languages.filter((itm, ind) => (ind != index))
        setPersonalDetails((prev) => ({ ...prev, primary_languages: [...data] }))
    }

    useEffect(() => {
        console.log(personalDetails);
    }, [personalDetails])

    const handleSlider = (e) => {

    }

    return (
        <>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.name', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                    </RobotoTypo>
                    <TextField value={name} name="name" onChange={handleChange} />
                    {form1Errors?.name !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.name} </ErrTypo> </Box>}
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.passport_name', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                    </RobotoTypo>
                    <TextField value={passport_name} name="passport_name" onChange={handleChange} />
                    {form1Errors?.passport_name !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.passport_name} </ErrTypo> </Box>}
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.religious_beliefs', { returnObjects: true })}
                    </RobotoTypo>
                    <TextField value={religious_belief} name="religious_belief" onChange={handleChange} />
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.country_of_birth', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                    </RobotoTypo>
                    <TextField value={country_of_birth} name="country_of_birth" onChange={handleChange} />
                    {form1Errors?.country_of_birth !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.country_of_birth} </ErrTypo> </Box>}
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.school_name', { returnObjects: true })} 
                    </RobotoTypo>
                    <TextField value={school_name} name="school_name" onChange={handleChange} />
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.recent_grade_attended', { returnObjects: true })}
                    </RobotoTypo>
                    <TextField value={recent_grade_attended} name="recent_grade_attended" onChange={handleChange} />
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Expected_Enrolment_Date', { returnObjects: true })} 
                    </RobotoTypo>
                    {/* <TextField value={expected_enrolment_date} name="expected_enrolment_date" onChange={handleChange} /> */}
                    <DatePicker value={expected_enrolment_date} name="expected_enrolment_date" onChange={(e) => setPersonalDetails((prev) => ({ ...prev, ["expected_enrolment_date"]: e }))} />
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Grade_Applying_For', { returnObjects: true })} 
                    </RobotoTypo>
                    <TextField value={grade_applying_for} name="grade_applying_for" onChange={handleChange} />
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.School_Address', { returnObjects: true })}
                    </RobotoTypo>
                    <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                        multiline value={school_address}
                        name="school_address" onChange={handleChange}
                    />
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1.2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.School_Contact_Phone', { returnObjects: true })} 
                    </RobotoTypo>
                    {/* <TextField value={school_phone} name="school_phone" onChange={handleChange} /> */}
                    <PhoneInput value={school_contact}
                        onChange={(value, country, e) => handleMobileInput(value, country)}
                        // inputProps={{ name: "phone", maxLength: 20 }}
                        country={"sg"}
                        buttonStyle={{ border: "none" }}
                        inputStyle={{
                            height: "54px",
                            width: "100%",
                            marginTop: '10px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 4px 0px #00000040 inset',
                            "& fieldset": { border: "none", },
                            "& .MuiInputBase-input": {
                                padding: '14px 14px',
                            },
                            // "&>.react-tel-input .flag-dropdown ": {
                            //     backgroundColor: 'white',
                            // }
                        }}
                        countryCodeEditable={false} />
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.School_Contact_Email', { returnObjects: true })}
                    </RobotoTypo>
                    <TextField value={school_email} name="school_email" onChange={handleChange} />
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Teacher_Name', { returnObjects: true })} 
                    </RobotoTypo>
                    <TextField value={teacher_name} name="teacher_name" onChange={handleChange} />
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Teacher_Email', { returnObjects: true })} 
                    </RobotoTypo>
                    <TextField value={teacher_email} name="teacher_email" onChange={handleChange} />
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Residential_Address', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                    </RobotoTypo>
                    <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                        multiline value={residential_address}
                        name="residential_address" onChange={handleChange}
                    />
                    {form1Errors?.residential_address !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.residential_address} </ErrTypo> </Box>}
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Address_five_years', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                    </RobotoTypo>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.Address_five_years_one', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                    </RobotoTypo>
                    {/* <Radio,RadioGroup,FormControlLabel /> */}
                    <RadioGroup row value={Is_lived_in_pastfiveyears} name="Is_lived_in_pastfiveyears" onChange={handleChange}>
                        <FormControlLabel
                            value='true'
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value='false'
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="top"
                        />
                    </RadioGroup>
                    {form1Errors?.Is_lived_in_pastfiveyears !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.Is_lived_in_pastfiveyears} </ErrTypo> </Box>}
                    {Is_lived_in_pastfiveyears == 'false' &&
                        <Box mt={2}>
                            <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                2. {t('ApplicationForm.Address_five_years_two', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                            </RobotoTypo>
                            <TextField sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                                multiline value={additional_info}
                                name="additional_info" onChange={handleChange}
                            />
                            {form1Errors?.additional_info !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.additional_info} </ErrTypo> </Box>}
                        </Box>
                    }

                </Grid>
            </Grid>
            <Box xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Language_Survey', { returnObjects: true })}
                </RobotoTypo>
            </Box>
            <Divider
                sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                }}
            />
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12}  >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.Primary_Language', { returnObjects: true })} 
                    </RobotoTypo>
                </Grid>
                {primary_languages?.map((item, index) => (
                    <>
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Language', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField value={item?.name} name="name" onChange={(e) => handleChange(e, 'primary_languages', index)} />
                        </Grid>
                        <Grid xs={12} md={5} columnGap={4} sx={{ display: 'flex', alignItems: "center" }}>
                            <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Level', { returnObjects: true })} 
                            </RobotoTypo>
                            <RadioGroup row value={item?.level} name="level" onChange={(e) => handleChange(e, 'primary_languages', index)} >
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label={t('ApplicationForm.High', { returnObjects: true })}
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio />}
                                    label={t('ApplicationForm.Medium', { returnObjects: true })}
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value={3}
                                    control={<Radio />}
                                    label={t('ApplicationForm.Low', { returnObjects: true })}
                                    labelPlacement="start"
                                />
                            </RadioGroup>
                            {index >= 1 && <IconButton onClick={() => { handleDeletelanguages(index) }} sx={{ color: "red", ml: 3 }}><RemoveCircleOutlineIcon /></IconButton>}
                        </Grid>
                    </>
                ))}
                <Stack direction="row" justifyContent="center">
                    <Button variant="Standard" onClick={() => setPersonalDetails((prev) => ({ ...prev, primary_languages: [...prev["primary_languages"], { name: "", level: "" }] }))} sx={{ fontSize: "16px !important", padding: "5px 11px !important" }}>Add More</Button>
                </Stack>
                <Grid xs={12}  >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.languages_level_title', { returnObjects: true })}  
                    </RobotoTypo>
                </Grid>
                {language_levels?.map((itm, index) => (
                    <>
                        <Grid xs={12} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>{itm?.name} { index ==0 && <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>}</RobotoTypo> 
                        </Grid>

                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Speaking', { returnObjects: true })}  
                            </RobotoTypo>
                            <Select value={itm?.speaking} name="speaking" onChange={(e) => handleChange(e, 'language_levels', index)}>
                                <MenuItem value={1}>{t('ApplicationForm.fluent', { returnObjects: true })}</MenuItem>
                                <MenuItem value={2}>{t('ApplicationForm.intermediate', { returnObjects: true })}</MenuItem>
                                <MenuItem value={3}>{t('ApplicationForm.beginner', { returnObjects: true })}</MenuItem>
                                <MenuItem value={4}>{t('ApplicationForm.undeveloped', { returnObjects: true })}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Reading', { returnObjects: true })}  
                            </RobotoTypo>
                            <Select value={itm?.reading} name="reading" onChange={(e) => handleChange(e, 'language_levels', index)}>
                                <MenuItem value={1}>{t('ApplicationForm.fluent', { returnObjects: true })}</MenuItem>
                                <MenuItem value={2}>{t('ApplicationForm.intermediate', { returnObjects: true })}</MenuItem>
                                <MenuItem value={3}>{t('ApplicationForm.beginner', { returnObjects: true })}</MenuItem>
                                <MenuItem value={4}>{t('ApplicationForm.undeveloped', { returnObjects: true })}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Writing', { returnObjects: true })} 
                            </RobotoTypo>
                            <Select value={itm?.writing} name="writing" onChange={(e) => handleChange(e, 'language_levels', index)}>
                                <MenuItem value={1}>{t('ApplicationForm.fluent', { returnObjects: true })}</MenuItem>
                                <MenuItem value={2}>{t('ApplicationForm.intermediate', { returnObjects: true })}</MenuItem>
                                <MenuItem value={3}>{t('ApplicationForm.beginner', { returnObjects: true })}</MenuItem>
                                <MenuItem value={4}>{t('ApplicationForm.undeveloped', { returnObjects: true })}</MenuItem>
                            </Select>
                        </Grid>
                    </>
                ))}
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        3. {t('ApplicationForm.How_many_years_have_you_learned_English', { returnObjects: true })} 
                    </RobotoTypo>
                    <TextField value={years_learned_english} name="years_learned_english" onChange={handleChange} />
                </Grid>
                <Grid xs={12} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.what_kind_of_person_title', { returnObjects: true })}  
                    </RobotoTypo>
                </Grid>
                <Grid xs={12}>
                    <RobotoTypo mb={2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'center' }}>
                        {t('ApplicationForm.Ordinary', { returnObjects: true })}
                    </RobotoTypo>
                    {kind_of_person?.map((itm, index) => (
                        <Stack mb={2} columnGap={5} direction="row" justifyContent="center" alignItems="center">
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', minWidth: "140px", textAlign: 'center' }}>
                                {t(`ApplicationForm.${itm?.name1}`, { returnObjects: true })}
                            </RobotoTypo>
                            <Slider value={itm?.value} name="value" sx={{ ...customSlider }} step={10} onChange={(e) => handleChange(e, 'kind_of_person', index)} />
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', minWidth: "140px", textAlign: 'center' }}>
                                {t(`ApplicationForm.${itm?.name2}`, { returnObjects: true })}
                            </RobotoTypo>
                        </Stack>
                    ))}
                </Grid>

            </Grid>
            <Box display='flex' justifyContent="end" padding='2% 3%' sx={{ borderTop: "1px solid #E5E7EB", }}>
                <Button onClick={handleContinue} variant='Standard'>Continue</Button>
            </Box>
        </>
    )
}
