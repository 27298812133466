import React from 'react'
import { Box, Button } from '@mui/material';
import { RobotoTypo } from '../../utils/Typographies';
import { ReactComponent as DownloadArrow } from '../../assets/ServicesImages/DrAppImages/downloadArrow.svg';
import { ReactComponent as AppleIcon } from '../../assets/ServicesImages/DrAppImages/AppleIcon.svg';
import { ReactComponent as PlaystoreIcon } from '../../assets/ServicesImages/DrAppImages/PlaystoreIcon.svg';
import { ReactComponent as AppGalleryIcon } from '../../assets/ServicesImages/DrAppImages/AppGalleryIcon.svg';

export default function DownloadOptions() {
    return (
        <Box sx={{ display: 'flex', alignSelf: 'center', gap: {xs:'15px', md:'30px'}, flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ display: 'flex', gap: '10px', flexDirection: { xs: 'column', md: 'row' }, justifyContent:'center' }}>

            <Button onClick={() => window.open('https://play.google.com/store/apps/details?id=com.vinga')} sx={{
                    borderRadius: '5px', color: '#FFFFFF', cursor: 'pointer', backgroundColor: '#000000', padding: '8px 16px',
                    '&:hover': {
                        backgroundColor: '#000000',
                    },
                }} variant='oulined' startIcon={<PlaystoreIcon />}><Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '400', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '8px', textAlign: 'left', pb:0.5 }}>GET IT ON</RobotoTypo>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '16px', textAlign: 'left' }}>Google Play</RobotoTypo>
                    </Box></Button>

                <Button onClick={() => window.open('https://www.apple.com/in/app-store/')} sx={{
                    borderRadius: '5px', color: '#FFFFFF', cursor: 'pointer', backgroundColor: '#000000', padding:  '8px 16px',
                    '&:hover': {
                        backgroundColor: '#000000',
                    },
                }} variant='oulined' startIcon={<AppleIcon />}><Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '400', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '8px', textAlign: 'left', pb:0.5  }}>Download on the</RobotoTypo>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '14px', textAlign: 'left' }}>App Store</RobotoTypo>
                    </Box></Button>

                <Button onClick={() => window.open('https://appgallery.huawei.com/Featured')} sx={{
                    borderRadius: '5px', color: '#FFFFFF', cursor: 'pointer', backgroundColor: '#000000', padding:  '8px 16px',
                    '&:hover': {
                        backgroundColor: '#000000',
                    },
                }} variant='oulined' startIcon={<AppGalleryIcon />}><Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '400', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '8px', textAlign: 'left',  pb:0.5  }}>EXPLORE IT ON</RobotoTypo>
                        <RobotoTypo sx={{ textTransform: 'none', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '12px', color: '#FFFFFF', fontSize: '14px', textAlign: 'left' }}>App Galery</RobotoTypo>
                    </Box></Button>

            </Box>

        </Box>
    )
}
