import React, { useState } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel, Button, Grid, Stack, Divider, TextareaAutosize, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { RobotoTypo } from '../../../utils/Typographies'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Hover1 } from '../../../assets/ContactUs/DateIcon.svg';
import { useTranslation } from 'react-i18next';
import { Grade } from '@mui/icons-material';
import moment from 'moment';

export default function ReviewUniversityApplication({ setFormPage, personalDetails, fatherDetails, motherDetails, spouseDetils, sibilingDetails, fundingDetails, educationalDetails,  academicDetails,  workExperince,  otherDetails, SubmitData, schoolName}) {

    const { t } = useTranslation();


console.log('otherDetails ',fatherDetails)
    return (
        <>
        <Box sx={{ backgroundColor: "#F7F7F7", margin: { md: '0 8%', xs: '0 2%' }, borderBottomLeftRadius: '20px', borderBottomRightRadius:'20px' }}>
            {/* PersonalDetails */}
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.student_name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.student_name}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.dob', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.dob}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.nationality', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.nationality}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('UniversityApplicationForm.email', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.email}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.documents_held', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.documents_held}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.passport_no', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.passport_number}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.have_you_studied', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.have_you_studied}</RobotoTypo> </Grid>
                    {/* <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{moment(expected_enrolment_date).format("DD-MM-YYYY")}</RobotoTypo> </Grid> */}
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.selected_school', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{schoolName}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.academic_level', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.academic_level}</RobotoTypo>
                </Grid>

                <Grid md={12}>
                    <RobotoTypo mb={2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('UniversityApplicationForm.course_nature', { returnObjects: true })}
                    </RobotoTypo>
                    {/* <Radio,RadioGroup,FormControlLabel /> */}
                    <RadioGroup disabled row value={personalDetails.course_nature} name="course_nature" >
                        <FormControlLabel
                            value='Full Time'
                            control={<Radio />}
                            label={t('UniversityApplicationForm.full_time', { returnObjects: true })}
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value='Part time'
                            control={<Radio />}
                            label={t('UniversityApplicationForm.part_time', { returnObjects: true })}
                            labelPlacement="top"
                        />
                    </RadioGroup>

                </Grid>

                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1.2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.courses_enrolled', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.courses_enrolled}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.mobile_phone_number', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.mobile_number}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.birth_certificate_number', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.birth_certificateNo}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                        <RobotoTypo mb={2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.student_title', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}>*</Box>
                        </RobotoTypo>
                        <RadioGroup disabled row  name="student_title" value={personalDetails.student_title} >
                            <FormControlLabel
                                sx={{ml:0,color: '#003B5C', fontSize: '14px',}}
                                value='Mr'
                                control={<Radio  name="student_title"/>}
                                label={t('UniversityApplicationForm.mr', { returnObjects: true })}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Miss'
                                control={<Radio  name="student_title"/>}
                                label={t('UniversityApplicationForm.miss', { returnObjects: true })}
                                labelPlacement="top"
                            />
                              <FormControlLabel
                                sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Mrs'
                                control={<Radio  name="student_title"/>}
                                label={t('UniversityApplicationForm.mrs', { returnObjects: true })}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Ms'
                                control={<Radio  name="student_title"/>}
                                label={t('UniversityApplicationForm.ms', { returnObjects: true })}
                                labelPlacement="top"
                            />
                        </RadioGroup>
                        </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.marriage_status', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.marraige_status}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.home_address', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.home_address}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.singapore_address', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.singapore_address}</RobotoTypo>
                </Grid>
            </Grid>

            <Divider
                sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                }}
            />
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12}  >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.emergency_contact', { returnObjects: true })} 
                    </RobotoTypo>
                </Grid>

                <Grid xs={12} md={5} >
                  <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                  {t('UniversityApplicationForm.name', { returnObjects: true })}
                  </RobotoTypo>
                  <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.emergency_contact.name}</RobotoTypo>
                </Grid>

                <Grid xs={12} md={5} >
                  <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                  {t('UniversityApplicationForm.relation', { returnObjects: true })}
                  </RobotoTypo>
                  <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.emergency_contact.relation}</RobotoTypo>
                </Grid>

                <Grid xs={12} md={5} >
                  <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                  {t('UniversityApplicationForm.address_postal', { returnObjects: true })}
                  </RobotoTypo>
                  <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.emergency_contact.addressAndPostal}</RobotoTypo>
                </Grid>

                <Grid xs={12} md={5} >
                  <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                  {t('UniversityApplicationForm.phone_number', { returnObjects: true })}
                  </RobotoTypo>
                  <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{personalDetails.emergency_contact.phoneNumber}</RobotoTypo>
                </Grid>
                
                {/* {primary_languages?.map((item, index) => (
                    <>
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Language', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item?.name}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={5} columnGap={4} sx={{ display: 'flex', alignItems: "center" }}>
                            <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Level', { returnObjects: true })}:
                            </RobotoTypo>
                            <RobotoTypo sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item?.level == 1 ? "High" : item?.level == 2 ? "Medium" : "Low"}</RobotoTypo>
                        </Grid>
                    </>
                ))} */}

                {/* <Grid xs={12}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.languages_level_title', { returnObjects: true })}
                    </RobotoTypo>
                </Grid> */}

                {/* {language_levels?.map((itm, index) => (
                    <>
                        <Grid xs={12} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>{itm?.name}</RobotoTypo>
                        </Grid>

                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Speaking', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.speaking == 1 ? "fluent" : itm?.speaking == 2 ? "intermediate" : itm?.speaking == 3 ? "beginner" : "undeveloped"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Reading', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.reading == 1 ? "fluent" : itm?.reading == 2 ? "intermediate" : itm?.reading == 3 ? "beginner" : "undeveloped"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Writing', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.writing == 1 ? "fluent" : itm?.writing == 2 ? "intermediate" : itm?.writing == 3 ? "beginner" : "undeveloped"}</RobotoTypo>
                        </Grid >
                    </>
                ))
                } */}
                {/* <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        3. {t('ApplicationForm.How_many_years_have_you_learned_English', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{years_learned_english}</RobotoTypo>
                </Grid>
                <Grid xs={12} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.what_kind_of_person_title', { returnObjects: true })}
                    </RobotoTypo>
                </Grid>
                <Grid xs={12}>
                    <RobotoTypo mb={2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'center' }}>
                        {t('ApplicationForm.Ordinary', { returnObjects: true })}
                    </RobotoTypo>
                    {kind_of_person?.map((itm, index) => (
                        <Stack mb={2} columnGap={5} direction="row" justifyContent="center" alignItems="center">
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', minWidth: "140px", textAlign: 'center' }}>
                                {t(`ApplicationForm.${itm?.name1}`, { returnObjects: true })}
                            </RobotoTypo>
                            <Slider value={itm?.value} name="value" sx={{ ...customSlider }} step={10} />
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', minWidth: "140px", textAlign: 'center' }}>
                                {t(`ApplicationForm.${itm?.name2}`, { returnObjects: true })}
                            </RobotoTypo>
                        </Stack>
                    ))}
                </Grid> */}
            </Grid >
            
            <Divider
                sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                }}
            />

            <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.family_information.father_info_title', { returnObjects: true })}
                </RobotoTypo>
            </Grid>
          <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails.name}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.nationality', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails.nationality}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.dob', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.dob}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.occupation', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails.occupation}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.documents_held', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails.documents_held}</RobotoTypo>
                </Grid>
              </Grid>

              <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.family_information.mother_info_title', { returnObjects: true })}
                </RobotoTypo>
              </Grid>

              <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails.name}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.nationality', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails.nationality}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.dob', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.dob}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.occupation', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails.occupation}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.family_information.documents_held', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails.documents_held}</RobotoTypo>
                </Grid>
              </Grid>

              <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.spouse_information.spouse_info_title', { returnObjects: true })}
                </RobotoTypo>
              </Grid>

              <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.spouse_information.name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{spouseDetils.name}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.spouse_information.marriage_regno', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{spouseDetils.marraige_Regno}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.spouse_information.marriage_date', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{spouseDetils.marraige_date}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.spouse_information.divorce_date', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{spouseDetils.divorce_date}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.spouse_information.divorce_number', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{spouseDetils.divorce_number}</RobotoTypo>
                </Grid>
              </Grid>

              <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.sibling_information.sibilng_info_title', { returnObjects: true })}
                </RobotoTypo>
              </Grid>

              <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.sibling_information.name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{sibilingDetails.name}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.sibling_information.relationship', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{sibilingDetails.relationship}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.sibling_information.dob', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{sibilingDetails?.dob}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.sibling_information.nationality', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{sibilingDetails.nationality}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.sibling_information.occupation', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{sibilingDetails.occupation}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.sibling_information.documents_held', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{sibilingDetails.documents_held}</RobotoTypo>
                </Grid>
              </Grid>

              <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.funding_information.funding_information_title', { returnObjects: true })}
                </RobotoTypo>
              </Grid>

              <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
              <Grid md={12}>
                      <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                      1. {t('UniversityApplicationForm.funding_information.applicant', { returnObjects: true })}
                      </RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.funding_information.monthly_income', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fundingDetails.applicant_income}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.funding_information.deposits', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fundingDetails.applicant_deposits}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                      <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                          2. {t('UniversityApplicationForm.funding_information.father', { returnObjects: true })}
                      </RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.funding_information.monthly_income', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fundingDetails.father_income}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.funding_information.deposits', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fundingDetails.father_deposits}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                      <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                      3. {t('UniversityApplicationForm.funding_information.mother', { returnObjects: true })}
                      </RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.funding_information.monthly_income', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fundingDetails.mother_income}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.funding_information.deposits', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fundingDetails.mother_deposits}</RobotoTypo>
                </Grid>

                <Grid md={12}>
                      <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                      4. {t('UniversityApplicationForm.funding_information.spouse', { returnObjects: true })}
                      </RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.funding_information.monthly_income', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fundingDetails.spouse_income}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.funding_information.deposits', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fundingDetails.spouse_deposits}</RobotoTypo>
                </Grid>
              </Grid>

              <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.educational_background.educational_background_title', { returnObjects: true })}
                </RobotoTypo>
              </Grid>

               <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>

                    {educationalDetails?.map((item, index) => (
                      <>
                      <Grid xs={12} md={5}>
                         <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                         {t('UniversityApplicationForm.educational_background.school')}
                         </RobotoTypo>
                         <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.school}</RobotoTypo>
                     </Grid>
                     <Grid xs={12} md={5}>
                         <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                         {t('UniversityApplicationForm.educational_background.highest_education')}
                         </RobotoTypo>
                         <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.highest_education}</RobotoTypo>
                     </Grid>
                     <Grid xs={12} md={5}>
                         <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                         {t('UniversityApplicationForm.educational_background.admission_date')}
                         </RobotoTypo>
                         <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.admission_date}</RobotoTypo>
                     </Grid>
                     <Grid xs={12} md={5}>
                         <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                         {t('UniversityApplicationForm.educational_background.completion_date')}
                         </RobotoTypo>
                         <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.completion_date}</RobotoTypo>
                     </Grid>
                     <Grid xs={12} md={5}>
                         <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                         {t('UniversityApplicationForm.educational_background.finished_condition')}
                         </RobotoTypo>
                         <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.finished_condition}</RobotoTypo>
                     </Grid>
                     <Grid xs={12} md={5}>
                         <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                         {t('UniversityApplicationForm.educational_background.course_nature')}
                         </RobotoTypo>
                         <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.course_nature}</RobotoTypo>
                     </Grid>
                     <Grid md={12} marginBottom={'20px'}>
                         <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                         {t('UniversityApplicationForm.educational_background.language')}
                         </RobotoTypo>
                         <RobotoTypo my={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.language}</RobotoTypo>
                         <Divider sx={{borderTop: "1px solid #F3F4F6", width: "100%",}}/>
                      </Grid>
                     </>
                    ))}
                </Grid> 

                <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                Relavant Details
                </RobotoTypo>
              </Grid>

              <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.educational_background.gpa_score', { returnObjects: true })}
                    </RobotoTypo>
                    <RadioGroup disabled row name="gpa_score" value={otherDetails.gpa_score} >
                            <FormControlLabel
                             sx={{  color: '#003B5C', fontSize: '14px', ml:0, mt:1  }}
                                value='Yes'
                                control={<Radio  name="gpa_score" />}
                                label={t('UniversityApplicationForm.educational_background.yes', { returnObjects: true })}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                              sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='No'
                                control={<Radio  name="gpa_score" />}
                                label={t('UniversityApplicationForm.educational_background.no', { returnObjects: true })}
                                labelPlacement="top"
                            />
                        </RadioGroup>
                </Grid>

                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.educational_background.provide_grade', { returnObjects: true })}
                    </RobotoTypo>
                    <RadioGroup disabled row name="provide_grade" value={otherDetails.provide_grade}>
                            <FormControlLabel
                             sx={{  color: '#003B5C', fontSize: '14px',   }}
                                value='AS?'
                                control={<Radio  name="provide_grade" />}
                                label={'AS?'}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                              sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Bs?'
                                control={<Radio  name="provide_grade" />}
                                label={'Bs?'}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                             sx={{  color: '#003B5C', fontSize: '14px',   }}
                                value='Cs?'
                                control={<Radio  name="provide_grade" />}
                                label={'Cs?'}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                              sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Ds?'
                                control={<Radio  name="provide_grade" />}
                                label={'Ds?'}
                                labelPlacement="top"
                            />
                             <FormControlLabel
                             sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Es?'
                                control={<Radio  name="provide_grade" />}
                                label={'Es?'}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                              sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Passes?'
                                control={<Radio  name="provide_grade" />}
                                label={'Passes?'}
                                labelPlacement="top"
                            />
                              <FormControlLabel
                                sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Fs?'
                                control={<Radio  name="provide_grade" />}
                                label={'Fs?'}
                                labelPlacement="top"
                            />
            </RadioGroup>
                </Grid>
                <Grid xs={12} md={5} >
                  <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                  {t('UniversityApplicationForm.educational_background.ielts_test', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                  </RobotoTypo>
                  {/* <TextField name='name' value={fatherDetails.name} onChange={handleFatherDetails} /> */}
                  <RadioGroup disabled row name="ielts_test" value={otherDetails.ielts_test}>
                                  <FormControlLabel
                                  sx={{  color: '#003B5C', fontSize: '14px', ml:0, mt:1  }}
                                      value='Yes'
                                      control={<Radio  name="ielts_test" />}
                                      label={t('UniversityApplicationForm.educational_background.yes', { returnObjects: true })}
                                      labelPlacement="top"
                                  />
                                  <FormControlLabel
                                    sx={{  color: '#003B5C', fontSize: '14px',  }}
                                      value='No'
                                      control={<Radio  name="ielts_test" />}
                                      label={t('UniversityApplicationForm.educational_background.no', { returnObjects: true })}
                                      labelPlacement="top"
                                  />
                  </RadioGroup>
                </Grid>

                <Grid xs={12} md={5} >
                  <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                  {t('UniversityApplicationForm.educational_background.provide_ielts', { returnObjects: true })} 
                  </RobotoTypo>
                  <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherDetails.score}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                  <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                  {t('UniversityApplicationForm.educational_background.singapore_address', { returnObjects: true })}
                  </RobotoTypo>
                  <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherDetails.singapore_address}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.educational_background.residential_address', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherDetails.residential_address}</RobotoTypo>
                  </Grid>
                  <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.educational_background.checkin_time', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherDetails.checkin_time}</RobotoTypo>
                  </Grid>
                  <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.educational_background.departure_time', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherDetails.departure_time}</RobotoTypo>
                  </Grid>
                  <Grid  md={12}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('UniversityApplicationForm.educational_background.refused_country', { returnObjects: true })}
                    </RobotoTypo>
                    <RadioGroup disabled row name="refused_country" value={otherDetails.refused_country} >
                        <FormControlLabel
                          sx={{  color: '#003B5C', fontSize: '14px', ml:0, mt:1  }}
                            value='Yes'
                            control={<Radio  name="refused_country" />}
                            label={t('UniversityApplicationForm.educational_background.yes', { returnObjects: true })}
                            labelPlacement="top"
                        />
                        <FormControlLabel
                          sx={{  color: '#003B5C', fontSize: '14px',  }}
                            value='No'
                            control={<Radio  name="refused_country" />}
                            label={t('UniversityApplicationForm.educational_background.no', { returnObjects: true })}
                            labelPlacement="top"
                        />
                    </RadioGroup>
               </Grid>

               <Grid  md={12}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                    {t('UniversityApplicationForm.educational_background.banned_entry', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                </RobotoTypo>
                <RadioGroup disabled row name="banned_entry" value={otherDetails.banned_entry} >
                    <FormControlLabel
                      sx={{  color: '#003B5C', fontSize: '14px', ml:0, mt:1  }}
                        value='Yes'
                        control={<Radio  name="banned_entry" />}
                        label={t('UniversityApplicationForm.educational_background.yes', { returnObjects: true })}
                        labelPlacement="top"
                    />
                    <FormControlLabel
                      sx={{  color: '#003B5C', fontSize: '14px',  }}
                        value='No'
                        control={<Radio  name="banned_entry" />}
                        label={t('UniversityApplicationForm.educational_background.no', { returnObjects: true })}
                        labelPlacement="top"
                    />
                </RadioGroup>
            </Grid>

            <Grid xs={12} md={5}>
            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.educational_background.other_passport', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
            </RobotoTypo>
            {/* <TextField name='name' value={fatherDetails.name} onChange={handleFatherDetails} /> */}
            <RadioGroup disabled row name="other_passport" value={otherDetails.other_passport} >
              <FormControlLabel
                sx={{  color: '#003B5C', fontSize: '14px', ml:0, mt:1  }}
                  value='Yes'
                  control={<Radio  name="other_passport" />}
                  label={t('UniversityApplicationForm.educational_background.yes', { returnObjects: true })}
                  labelPlacement="top"
              />
              <FormControlLabel
                sx={{  color: '#003B5C', fontSize: '14px',  }}
                  value='No'
                  control={<Radio  name="other_passport" />}
                  label={t('UniversityApplicationForm.educational_background.no', { returnObjects: true })}
                  labelPlacement="top"
              />
            </RadioGroup>
        </Grid>

        <Grid xs={12} md={5} >
          <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
          {t('UniversityApplicationForm.educational_background.provide_ielts', { returnObjects: true })}
          </RobotoTypo>
          <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherDetails.reason_other_passport}</RobotoTypo>
        </Grid>

        </Grid>

        <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
          <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
          {t('UniversityApplicationForm.academic_background.academic_background_title', { returnObjects: true })}
          </RobotoTypo>
        </Grid>
        
        <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>

            {academicDetails?.map((item, index) => (
              <>
              <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.academic_background.graduation_school')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.graduation_school}</RobotoTypo>
            </Grid>
            <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.academic_background.major')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.major}</RobotoTypo>
            </Grid>
            <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.academic_background.admission_time')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.admission_time}</RobotoTypo>
            </Grid>
            <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.academic_background.graduation_time')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.graduation_time}</RobotoTypo>
            </Grid>
            <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.academic_background.academic_certificate_number')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.academic_certificateNo}</RobotoTypo>
            </Grid>
            <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.academic_background.highest_education')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.highest_education}</RobotoTypo>
            </Grid>
            </>
            ))}
        </Grid> 

          <Grid xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
            {t('UniversityApplicationForm.work_experience.work_experience_title', { returnObjects: true })}
            </RobotoTypo>
          </Grid>

          <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>

              {workExperince?.map((item, index) => (
              <>
              <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.work_experience.company_name')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.company_name}</RobotoTypo>
              </Grid>
              <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.work_experience.nation')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.nation}</RobotoTypo>
              </Grid>
              <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.work_experience.position')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.position}</RobotoTypo>
              </Grid>
              <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.work_experience.entry_time')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.entry_time}</RobotoTypo>
              </Grid>
              <Grid xs={12} md={5}>
                <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                {t('UniversityApplicationForm.work_experience.resignation_time')}
                </RobotoTypo>
                <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item.resignation_time}</RobotoTypo>
              </Grid>
              </>
              ))}
              </Grid> 

                <Box display='flex' justifyContent="space-between" padding='2% 3%' sx={{ borderTop: "1px solid #E5E7EB", }}>
                    <Button onClick={() => { setFormPage(2) }} variant='Standard'>Back</Button>
                    <Button onClick={() => {SubmitData()}} variant='Standard'>Submit</Button>
                </Box>
                </Box>
        </>
        
    )
}
