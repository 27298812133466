import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import '../../../App.css';
import { useNavigate,useHistory  } from 'react-router-dom';


const SchoolFeesInfo = ({ data, schoolId }) => {
  console.log('schoolId ',data)
    const feesInfo = data?.fees_info ? data?.fees_info : ""
    const navigate = useNavigate()
    const schoolType = data?.school_type
    const { t } = useTranslation();

    const [rows, setRows] = useState([
        { description: `Service fee for applying ${(data?.service_fee_name !='undefined' && data?.service_fee_name !='') ? data?.service_fee_name : ''}`, charge: data?.service_fee_amount != 'undefined' ? parseInt(data?.service_fee_amount) : 0 },
        { description: `Application fee for ${(data?.Application_fee_name != 'undefined' && data?.Application_fee_name != '') ? data?.Application_fee_name : ''} `, charge: data?.Application_fee_amount != 'undefined' ? parseInt(data?.Application_fee_amount) : 0 },
        { description: 'Translation and notarization service', charge: data?.Trans_nota_service != 'undefined' ? parseInt(data?.Trans_nota_service) : 0 },
        { description: 'Registration fee for preparatory course for admission to international school package (one-time payment)', charge: data?.Registration_fee != 'undefined' ? parseInt(data?.Registration_fee) : 0 },
        { description: 'Preparatory course for admission to international schools (48 hours 1 to 1)', charge: data?.Preparatory_course_48hrs != 'undefined' ? parseInt(data?.Preparatory_course_48hrs) : 0 },
        { description: 'Preparatory course for admission to international schools (24 hours 1 to 1)', charge: data?.Preparatory_course_24hrs != 'undefined' ? parseInt(data?.Preparatory_course_24hrs) : 0 },
        { description: 'International bank remittance charge', charge: data?.Internati_bank_remittance_charge != 'undefined' ? parseInt(data?.Internati_bank_remittance_charge) : 0 },
        { description: `${( data?.other_name != undefined && data?.other_name !='undefined' && data?.other_name !='') ? data?.other_name : 'Other'}`, charge: data?.other_fee != 'undefined' ? parseInt(data?.other_fee) : 0 }
      ]);

      const totalCharge = rows.reduce((total, row) => total + row.charge, 0);
      console.log('data ', data?.fees_info !="" && totalCharge !=0)

    const handleApplyNow = () => {
      const studentToken = localStorage.getItem('student_token')
      const roleName = localStorage.getItem('role_name')
      if(studentToken && roleName == "Student") {
        navigate(schoolType == 'university' ? 'universityApplication' : 'SchoolApplicationForm')
        console.log('gone2')
      } else {
        console.log('gone')
        if(schoolType.includes('School')) {
          navigate('/login', { state: { from: 'school', routes: `/school/${schoolId}/SchoolApplicationForm` } });
        } 
        if(schoolType == 'university') {
          navigate('/login', { state: { from: 'university', routes: `/school/${schoolId}/universityApplication` } });
        }

      }
      
    }
    return (
        <Box sx={{ background: '#F5DFBA', padding: '5% 5%' }}>
            {((data?.fees_info == "[[]]" || data?.fees_info =="") && totalCharge ==0) ? 
           ("") : (
            <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '4%',
                background: '#F7F7F7',
                borderRadius: '50px'
            }}
        >
          {console.log('mariyadai')}
          {(data?.fees_info !="" && data?.fees_info != "[[]]") && <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px', width: 'fit-content' }
                } >
                    <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                        {t('School.schoolFeesInfo', { returnObjects: true }).title}
                    </RobotoTypo>
                </ Box>

            </Box>
            <RobotoTypo variant="body1" component="div" dangerouslySetInnerHTML={{ __html: feesInfo }} />
            </>}
            {console.log('totalCharge ',totalCharge)}
           {(totalCharge !=0 && !isNaN(totalCharge)) && <>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt:2 }}>
                <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px', width: 'fit-content' }
                } >
                  <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                      {/* {t('School.schoolFeesInfo', { returnObjects: true }).title} */}
                      {data?.application_title == "undefined" && "Application Fees"}
                  </RobotoTypo>
            </ Box>

            </Box>
            <RobotoTypo mt={2} sx={{ fontWeight: "500", fontSize: "20px" }}>Other Charges</RobotoTypo>
            <Box className='table' sx={{mt:2}}>
              <table >
                <thead>
                  <tr>
                    <th  style={{padding:'10px'}}><RobotoTypo sx={{fontWeight:'bold'}}>Description</RobotoTypo></th>
                    <th><RobotoTypo sx={{fontWeight:'bold'}}>Charge</RobotoTypo></th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      {console.log('row.charge ',row.charge)} 
                      <td><RobotoTypo sx={{fontSize:'16px'}}>{row.description}</RobotoTypo></td>
                      <td><RobotoTypo sx={{fontSize:'16px'}}>$ {isNaN(row.charge) ? 0 : row.charge }</RobotoTypo></td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td><RobotoTypo sx={{fontWeight:'bold'}}>Total</RobotoTypo></td>
                    <td><RobotoTypo sx={{fontWeight:'bold'}}>$ {isNaN(totalCharge) ? 0 : totalCharge }</RobotoTypo></td>
                  </tr>
                </tfoot>
              </table>
            </Box>
            </>}


        </Box>
           )
            }
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box onClick={handleApplyNow} sx={{ mt: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#003B5C', padding: '8px 18px', borderRadius: '50px', width: 'fit-content' }
                } >
                    <RobotoTypo sx={{ cursor: 'pointer', letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                    {t("ApplyNow")}
                    </RobotoTypo>
                </ Box>
            </Box>
        </Box>
    );
};

export default SchoolFeesInfo;
