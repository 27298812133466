import React, { useState, useEffect } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel, Button, Divider, Stack, Slider, Grid, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material'
import { RobotoTypo } from '../../../utils/Typographies'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import { customSlider } from "../../../utils/theme"
import moment from "moment"

export default function Reivew({ otherDetails, familyDetails, setFormPage, personalDetails, SubmitData, schoolName}) {
    const { t } = useTranslation();
    const { name, passport_name, religious_belief, country_of_birth, school_name, recent_grade_attended, expected_enrolment_date, grade_applying_for,
        school_address, school_phone, school_contact, school_email, teacher_name, teacher_email, residential_address, Is_lived_in_pastfiveyears, additional_info,
        primary_languages, language_levels, years_learned_english, kind_of_person, school_countryCode } = personalDetails

    const { fatherDetails, motherDetails, SiblingsInfo, grandmotherInfo } = familyDetails

    const { health_history_Details, otherQuestions, american_school_sg } = otherDetails

    console.log(expected_enrolment_date);

    return (
        <>

            {/* PersonalDetails */}

            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.passport_name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{passport_name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.religious_beliefs', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{religious_belief || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.country_of_birth', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{country_of_birth || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.school_name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{schoolName || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.recent_grade_attended', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{recent_grade_attended || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Expected_Enrolment_Date', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{expected_enrolment_date ? moment(expected_enrolment_date).format("DD-MM-YYYY") : "-"}</RobotoTypo> </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Grade_Applying_For', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{grade_applying_for || "-"}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.School_Address', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{school_address || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo mb={1.2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.School_Contact_Phone', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{`${school_countryCode || "-"} ${school_phone}`}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.School_Contact_Email', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{school_email || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Teacher_Name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{teacher_name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Teacher_Email', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{teacher_email || "-"}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Residential_Address', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{residential_address || "-"}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Address_five_years', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.Address_five_years_one', { returnObjects: true })}
                    </RobotoTypo>
                    {/* <Radio,RadioGroup,FormControlLabel /> */}
                    <RadioGroup disabled row value={Is_lived_in_pastfiveyears} name="Is_lived_in_pastfiveyears" >
                        <FormControlLabel
                            value='true'
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value='false'
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="top"
                        />
                    </RadioGroup>
                    {Is_lived_in_pastfiveyears == 'false' &&
                        <Box mt={2}>
                            <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                2. {t('ApplicationForm.Address_five_years_two', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{additional_info || "-"}</RobotoTypo>
                        </Box>
                    }

                </Grid>
            </Grid>
            <Box xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Language_Survey', { returnObjects: true })}
                </RobotoTypo>
            </Box>
            <Divider
                sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                }}
            />
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12}  >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.Primary_Languages', { returnObjects: true })}
                    </RobotoTypo>
                </Grid>
                {primary_languages?.map((item, index) => (
                    <>
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Language', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item?.name || "-"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={5} columnGap={4} sx={{ display: 'flex', alignItems: "center" }}>
                            <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Level', { returnObjects: true })}:
                            </RobotoTypo>
                            <RobotoTypo sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{item?.level == 1 ? "High" : item?.level == 2 ? "Medium" : "Low"}</RobotoTypo>
                        </Grid>
                    </>
                ))}
                <Grid xs={12}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.languages_level_title', { returnObjects: true })}
                    </RobotoTypo>
                </Grid>
                {language_levels?.map((itm, index) => (
                    <>
                        <Grid xs={12} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>{itm?.name}</RobotoTypo>
                        </Grid>

                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Speaking', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.speaking ? (itm?.speaking == 1 ? "fluent" : itm?.speaking == 2 ? "intermediate" : itm?.speaking == 3 ? "beginner" : "undeveloped") : "-"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Reading', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.reading ? (itm?.reading == 1 ? "fluent" : itm?.reading == 2 ? "intermediate" : itm?.reading == 3 ? "beginner" : "undeveloped") : "-"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={2.7} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Writing', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.writing ? (itm?.writing == 1 ? "fluent" : itm?.writing == 2 ? "intermediate" : itm?.writing == 3 ? "beginner" : "undeveloped") : "-"}</RobotoTypo>
                        </Grid >
                    </>
                ))
                }
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        3. {t('ApplicationForm.How_many_years_have_you_learned_English', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{years_learned_english || "_"}</RobotoTypo>
                </Grid>
                <Grid xs={12} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.what_kind_of_person_title', { returnObjects: true })}
                    </RobotoTypo>
                </Grid>
                <Grid xs={12}>
                    <RobotoTypo mb={2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'center' }}>
                        {t('ApplicationForm.Ordinary', { returnObjects: true })}
                    </RobotoTypo>
                    {kind_of_person?.map((itm, index) => (
                        <Stack mb={2} columnGap={5} direction="row" justifyContent="center" alignItems="center">
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', minWidth: "140px", textAlign: 'center' }}>
                                {t(`ApplicationForm.${itm?.name1}`, { returnObjects: true })}
                            </RobotoTypo>
                            <Slider value={itm?.value} name="value" sx={{ ...customSlider }} step={10} />
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', minWidth: "140px", textAlign: 'center' }}>
                                {t(`ApplicationForm.${itm?.name2}`, { returnObjects: true })}
                            </RobotoTypo>
                        </Stack>
                    ))}
                </Grid>
            </Grid >


            {/* FamilyDetails */}


            <Box xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1' }}>
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.father_info_title', { returnObjects: true })}
                </RobotoTypo>
            </Box>
            <Divider
                sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                }}
            />
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Average_monthly_income', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.avg_month_sal || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Current_deposit', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.current_deposit || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Phone', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{`${fatherDetails?.country_code || "-"} ${fatherDetails?.phone}`}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Email', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.email || "-"}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Highest_education_level', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.high_edu_level || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Occupation_type', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.occupation_type || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Position', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.position || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Company_Name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.company_name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Company_Phone', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{`${fatherDetails?.company_country_code || "-"} ${fatherDetails?.company_phone}`}</RobotoTypo>
                </Grid>
                <Grid md={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Company_Address', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{fatherDetails?.company_address || "-"}</RobotoTypo>
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center" }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Is_company_UnitedStates', { returnObjects: true })}
                    </RobotoTypo>
                    <RadioGroup disabled row name="Is_company_US" value={fatherDetails?.Is_company_US} >
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                py={2}
                sx={{
                    backgroundColor: "#e1e1e1",
                    borderBottom: "1px solid #E5E7EB",
                    borderTop: "1px solid #E5E7EB",
                }}
            >
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.mother_info_title', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Average_monthly_income', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.avg_month_sal || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Current_deposit', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.current_deposit || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Phone', { returnObjects: true })}
                    </RobotoTypo>
                    {/* <TextField name="phone" value={motherDetails?.phone}  /> */}
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{`${motherDetails?.country_code || "-"} ${motherDetails?.phone}`}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Email', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.email || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Highest_education_level', { returnObjects: true })}
                    </RobotoTypo> <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.high_edu_level || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Occupation_type', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.occupation_type || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Position', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.position || "_"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Company_Name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.company_name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Company_Phone', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{`${motherDetails?.company_country_code || "-"} ${motherDetails?.company_phone}`}</RobotoTypo>
                </Grid>
                <Grid xs={12}>
                    <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Company_Address', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{motherDetails?.company_address || "-"}</RobotoTypo>
                </Grid>
                <Grid md={12} columnGap={4} sx={{ display: 'flex', alignItems: "center" }}>
                    <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Is_company_UnitedStates', { returnObjects: true })}
                    </RobotoTypo>
                    <RadioGroup disabled row name="Is_company_US" value={motherDetails?.Is_company_US} >
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t('ApplicationForm.yes', { returnObjects: true })}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t('ApplicationForm.no', { returnObjects: true })}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Stack justifyContent="center" px="16px" py={2} sx={{ backgroundColor: "#e1e1e1", borderBottom: "1px solid #E5E7EB", borderTop: "1px solid #E5E7EB", }} >
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Applicant_Siblings_Information', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                {SiblingsInfo?.map((itm, index) => (
                    <>
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.name', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.name || "-"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.passport_name', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.passport_name || "-"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Relationship', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.relationship || "-"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.dob', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.dob ? moment(itm?.dob).format("DD-MM-YYYY") : "-"}</RobotoTypo>
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('ApplicationForm.Nationality', { returnObjects: true })}
                            </RobotoTypo>
                            <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{itm?.nationality || "-"}</RobotoTypo>
                        </Grid><br />
                    </>
                ))}
            </Grid>
            <Stack justifyContent="center" px="16px" py={2} sx={{ backgroundColor: "#e1e1e1", borderBottom: "1px solid #E5E7EB", borderTop: "1px solid #E5E7EB", }} >
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Applicant_Grandmother_Information', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{grandmotherInfo?.name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.passport_name', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{grandmotherInfo?.passport_name || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Relationship', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{grandmotherInfo?.relationship || "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.dob', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={2} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{grandmotherInfo?.dob ? moment(grandmotherInfo?.dob).format("DD-MM-YYYY") : "-"}</RobotoTypo>
                </Grid>
                <Grid xs={12} md={5}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Nationality', { returnObjects: true })}
                    </RobotoTypo>
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{grandmotherInfo?.nationality || "-"}</RobotoTypo>
                </Grid>
            </Grid>




            {/* OtherDetails */}



            <Stack justifyContent="center" px="16px" py={2} sx={{ backgroundColor: "#e1e1e1", borderBottom: "1px solid #E5E7EB", borderTop: "1px solid #E5E7EB", }} >

                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Development_health_history_Title', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid md={12} columnGap={4} >
                    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                        <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            1. {t('ApplicationForm.Development_health_history_1', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup disabled row name="have_physical_disability" value={health_history_Details?.have_physical_disability} >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={t('ApplicationForm.yes', { returnObjects: true })}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={t('ApplicationForm.no', { returnObjects: true })}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Box>
                    <br />
                    {(health_history_Details?.have_physical_disability == 'true') &&
                        <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{health_history_Details?.have_physical_disability_des || "-"}</RobotoTypo>
                    }
                </Grid>
                <Grid md={12} columnGap={4} >
                    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            2. {t('ApplicationForm.Development_health_history_2', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup disabled row name="have_serious_hlth_prob" value={health_history_Details?.have_serious_hlth_prob} >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={t('ApplicationForm.yes', { returnObjects: true })}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={t('ApplicationForm.no', { returnObjects: true })}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Box><br />
                    {(health_history_Details?.have_serious_hlth_prob == 'true') &&
                        <RobotoTypo sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{health_history_Details?.have_serious_hlth_prob_des || "-"}</RobotoTypo>
                    }
                </Grid>
                <Grid md={12} columnGap={4} >
                    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                        <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            3. {t('ApplicationForm.Development_health_history_3', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup disabled row name="difficulty_In_physical_skills" value={health_history_Details?.difficulty_In_physical_skills} >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={t('ApplicationForm.yes', { returnObjects: true })}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={t('ApplicationForm.no', { returnObjects: true })}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Box><br />
                    {(health_history_Details?.difficulty_In_physical_skills == 'true') &&
                        <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{health_history_Details?.difficulty_In_physical_skills_des}</RobotoTypo>
                    }
                </Grid>
                <Grid md={12} columnGap={4} >
                    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                        <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            4. {t('ApplicationForm.Development_health_history_4', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup disabled row name="had_trouble_paying_attention" value={health_history_Details?.had_trouble_paying_attention} >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={t('ApplicationForm.yes', { returnObjects: true })}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={t('ApplicationForm.no', { returnObjects: true })}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Box><br />
                    {(health_history_Details?.had_trouble_paying_attention == 'true') &&
                        <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{health_history_Details?.had_trouble_paying_attention_des}</RobotoTypo>
                    }
                </Grid>
                <Grid md={12} columnGap={4} >
                    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                        <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            5. {t('ApplicationForm.Development_health_history_5', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup disabled row name="have_allergies" value={health_history_Details?.have_allergies} >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={t('ApplicationForm.yes', { returnObjects: true })}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={t('ApplicationForm.no', { returnObjects: true })}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Box><br />
                    {(health_history_Details?.have_allergies == 'true') &&
                        <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{health_history_Details?.have_allergies_des}</RobotoTypo>
                    }
                </Grid>
                <Grid md={12} columnGap={4} >
                    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                        <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            6. {t('ApplicationForm.Development_health_history_6', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup disabled row name="Is_taking_any_medication" value={health_history_Details?.Is_taking_any_medication} >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={t('ApplicationForm.yes', { returnObjects: true })}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={t('ApplicationForm.no', { returnObjects: true })}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Box><br />
                    {(health_history_Details?.Is_taking_any_medication == 'true') &&
                        <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{health_history_Details?.Is_taking_any_medication_des}</RobotoTypo>
                    }
                </Grid>
                <Grid md={12} columnGap={4} >
                    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                        <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            7. {t('ApplicationForm.Development_health_history_7', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup disabled row name="can_use_toilet_independently" value={health_history_Details?.can_use_toilet_independently} >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={t('ApplicationForm.yes', { returnObjects: true })}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={t('ApplicationForm.no', { returnObjects: true })}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Box><br />
                    {(health_history_Details?.can_use_toilet_independently == 'true') &&
                        <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{health_history_Details?.can_use_toilet_independently_des}</RobotoTypo>
                    }
                </Grid>
                <Grid md={12} columnGap={4} >
                    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: 'wrap' }}>
                        <RobotoTypo my={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            8. {t('ApplicationForm.Development_health_history_8', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup disabled row name="can_wear_his_clothes" value={health_history_Details?.can_wear_his_clothes} >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={t('ApplicationForm.yes', { returnObjects: true })}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={t('ApplicationForm.no', { returnObjects: true })}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Box><br />
                    {(health_history_Details?.can_wear_his_clothes == 'true') &&
                        <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{health_history_Details?.can_wear_his_clothes_des}</RobotoTypo>
                    }
                </Grid>
            </Grid>
            <Stack justifyContent="center" px="16px" py={2} sx={{ backgroundColor: "#e1e1e1", borderBottom: "1px solid #E5E7EB", borderTop: "1px solid #E5E7EB", }} >
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Other_Questions', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.Other_Questions_1', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherQuestions?.like_most_in_shl}</RobotoTypo>
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.Other_Questions_2', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherQuestions?.fav_thing_todo_aft_cls}</RobotoTypo>
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        3. {t('ApplicationForm.Other_Questions_3', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherQuestions?.like_reading}</RobotoTypo>
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        4. {t('ApplicationForm.Other_Questions_4', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherQuestions?.sought_additional_support}</RobotoTypo>
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        5. {t('ApplicationForm.Other_Questions_5', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherQuestions?.attended_special_classes}</RobotoTypo>
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        6. {t('ApplicationForm.Other_Questions_6', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{otherQuestions?.hav_social_dev_prob}</RobotoTypo>
                </Grid>
            </Grid>
            <Stack justifyContent="center" px="16px" py={2} sx={{ backgroundColor: "#e1e1e1", borderBottom: "1px solid #E5E7EB", borderTop: "1px solid #E5E7EB", }} >
                <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                    {t('ApplicationForm.Applicants_American_School_Singapore', { returnObjects: true })}
                </RobotoTypo>
            </Stack>
            <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Applicant_Father', { returnObjects: true })}
                    </RobotoTypo><br />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.favorite_hobby', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{american_school_sg?.father_fav_hobby}</RobotoTypo>
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.Is_interesting_you’d_like_us_to_know', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{american_school_sg?.father_intersting_thing}</RobotoTypo>
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        {t('ApplicationForm.Applicant_Mother', { returnObjects: true })}
                    </RobotoTypo><br />
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        1. {t('ApplicationForm.favorite_hobby', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{american_school_sg?.mother_fav_hobby}</RobotoTypo>
                </Grid>
                <Grid xs={12} columnGap={4} >
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                        2. {t('ApplicationForm.Is_interesting_you’d_like_us_to_know', { returnObjects: true })}
                    </RobotoTypo><br />
                    <RobotoTypo mt={1} sx={{ fontWeight: '400', color: '#003B5C', fontSize: '16px', textAlign: 'left' }}>{american_school_sg?.mother_intersting_thing}</RobotoTypo>

                </Grid>
            </Grid>
            <Box display='flex' justifyContent="space-between" padding='2% 3%' sx={{ borderTop: "1px solid #E5E7EB", }}>
                <Button onClick={() => { setFormPage(3) }} variant='Standard'>Back</Button>
                <Button onClick={SubmitData} variant='Standard'>Submit</Button>
            </Box>

        </>
    )
}
