import React, { useEffect } from "react";
import Header from "../components/Header";
import ImageCarousel from "../components/LandingPage/ImageCarousel";
import Footer from "../components/Footer";
import Brands from "../components/LandingPage/Brands";
import ApplicationProcess from "../components/LandingPage/ApplicationProcess";
import Services from "../components/Services";
import News from "../components/LandingPage/News";
import Field from "../components/LandingPage/Field";
import Recommendation from "../components/LandingPage/Recomendation";
import Share from "../components/Share";
import SideOverlap from "./sideOverlap";
import VingaInfo from "../components/LandingPage/VingaInfo";
import FlowChart from "../components/LandingPage/FlowChart";
import { DocumentTitleChange } from "../utils/metaHeadTitle";

const Dashboard = () => {
  useEffect(() => {
    DocumentTitleChange("新加坡留學管家");
  });

  return (
    <>
      <Header />
      <ImageCarousel />
      <Brands />
      <ApplicationProcess />
      <VingaInfo />
      <Services />
      <FlowChart />
      <News />
      <Field />
      <Recommendation />
      <Share />
      <Footer />
      {window.location.pathname != "/admin/login" &&
        window.location.pathname != "/admin/admindashboard" && <SideOverlap />}
      {/*  </Header> */}
    </>
  );
};

export default Dashboard;
