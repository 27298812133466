import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "languageSlice",
  initialState: {
    language: "ch",
  },
  reducers: {
    toggleLanguage: (state, action) => {
      console.log("state.language ", action);
      state.language = action.payload == "en" ? "en" : "ch";
    },
  },
});

export const { actions, reducer } = languageSlice;
